// ----- Modules ----- //
import React from "react";
import moment from "moment/moment";
import { enqueueSnackbar } from "notistack";

// ----- MUI ----- //
import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  gridExpandedSortedRowIdsSelector,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  useGridApiContext
} from "@mui/x-data-grid-premium";
import { Box, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import UserIcon from "@mui/icons-material/PersonOutlineOutlined";

// ----- Components ----- //
import Tab, { handleColumnsSelector } from "./Tab";
import ActionBtns from "../../components/ActionBtns";
import Domain from "../../components/modal/Domain";
import CellBoolean from "../../components/datagrid/CellBoolean";

// ----- Utils ----- //
import { Pages } from "../../Utils/Types";
import { DomainsContext } from "../../contexts/DomainsProvider";
import { getSelectedTheme } from "../../Utils/Colors";
import CellDateTime from "../../components/datagrid/CellDateTime";

const getFilteredRows = ({apiRef}: GridCsvGetRowsToExportParams) =>
  gridExpandedSortedRowIdsSelector(apiRef);

function CustomToolbar() {
  const apiRef = useGridApiContext();

  // ----- Functions ----- //
  const handleExport = (options: GridCsvExportOptions) => {
    try {
      if (apiRef.current.getRowsCount() === 0)
        enqueueSnackbar('No domains to export.', {variant: 'warning'});
      else {
        const date = moment().format('YYYY-MM-DD');
        options.fileName = `domains-filtered_${date}.csv`;
        apiRef.current.exportDataAsCsv(options);
        enqueueSnackbar('Domains exported successfully!', {variant: 'success'});
      }
    } catch (e) {
      enqueueSnackbar('Failed to export domains.', {variant: 'error'});
    }
  };

  // ----- Render ----- //
  return (
    <Box sx={{py: 1.5}}>
      <Box sx={{display: 'none'}} id={'column-visibility'}><GridToolbarColumnsButton/></Box>
      <ActionBtns
        tab={Pages.domains}
        SelectColumns={handleColumnsSelector}
        exportElements={() => handleExport({getRowsToExport: getFilteredRows})}
        CreateElement={<Domain/>}
      />
    </Box>
  );
}

const columns = [
  {
    field: '*Edit Domain*', headerName: '', minWidth: 0, width: 40,
    filterable: false,
    renderHeader: () =>
      <Box sx={{
        color: getSelectedTheme().darkText,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <EditIcon/>
      </Box>,
    renderCell: () => {
      return (
        <Tooltip title={'Not implemented yet'} placement={'top'} arrow>
          <IconButton aria-label="Edit domain"
                      component="span" onClick={() => console.log('Edit domain')} sx={{p: 0}}>
            <EditIcon sx={{color: getSelectedTheme().darkText, fontSize: '24px'}}/>
          </IconButton>
        </Tooltip>
      );
    },
  },
  {field: 'id', headerName: 'ID', width: 70, hide: true},
  {field: 'domain', headerName: 'Domain', width: 250},
  {
    field: 'domain_provider',
    headerName: 'Provider',
    width: 130,
    renderCell: (params: any) => params.row.provider?.name
  },
  {
    field: 'is_ds', headerName: 'DS', width: 70, type: 'boolean',
    renderCell: (params: any) => CellBoolean(params.row.is_ds),
  },
  {
    field: 'updated_at', headerName: 'Updated At', width: 130, align: 'right',
    renderCell: (params: GridRenderCellParams<any, Date>) =>
      <CellDateTime value={params.row.updated_at !== params.row.created_at ? params.row.updated_at : null}/>,
    filterable: false
  },
  {
    field: 'created_at', headerName: 'Created At', width: 130, align: 'right',
    renderCell: (params: any) => <CellDateTime value={params.value}/>,
    filterable: false
  },
  {
    field: 'accounts',
    headerName: 'Accounts',
    width: 100,
    type: 'number',
    valueGetter: (_: any, row: any) => row.accounts.length,
    renderHeader: () => <Tooltip title={'Number of accounts using this domain'} placement={'top'} arrow>
      <UserIcon sx={{fontSize: '24px', color: getSelectedTheme().darkText}}/>
    </Tooltip>,
    filterable: false,
    sortable: false
  },
];

/**
 * Domains Page component. Displays a list of domains.
 */
const Domains = () => {
  return (
    <Tab columns={columns} toolbar={CustomToolbar} context={DomainsContext}/>
  );
};

export default Domains;
