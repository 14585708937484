export enum ColorThemeNames {
  ClassicBlue = 'Classic Blue',
  DuskBlossom = 'Dusk Blossom',
  AutumnBurst = 'Autumn Burst',
  StarryNight = 'Starry Night',
  Nautical = 'Nautical',
  EmeraldTwilight = 'Emerald Twilight',
}

interface ThemeDefinition {
  name: string;
  dark: string;
  light: string;
  accent: string;
  success: string;
  error: string;
  warning: string;
  lightText: string;
  darkText: string;
  darkLine: string;
  lightLine: string;
  darkGradient: string;
  lightGradient: string;
}

export const ColorThemes: Record<ColorThemeNames, ThemeDefinition> = {
  [ColorThemeNames.ClassicBlue]: {
    name: "Classic Blue",
    dark: "#121212",
    light: "#F2F2F2",
    accent: "#08659B",
    success: "#00860D",
    error: "#BE4747",
    warning: "#FFC107",
    lightText: "#A0A0A0",
    darkText: "#696969",
    darkLine: "#313131",
    lightLine: "rgba(190,190,190,0.3)",
    darkGradient: "linear-gradient(45deg, rgba(18, 18, 18, 1) 0%, rgba(30, 30, 30, 1) 50%, rgba(20, 20, 20, 1) 100%)",
    lightGradient: "linear-gradient(45deg, rgba(240, 240, 240, 1) 0%, rgba(230, 230, 230, 1) 50%, rgba(240, 240, 240, 1) 100%)",
  },
  [ColorThemeNames.DuskBlossom]: {
    name: "Dusk Blossom",
    dark: "#3D1C4D",          // Darker purple tone
    light: "#E6DEF5",         // Light shade influenced by the pink and blue in the gradient.
    accent: "#7580E1",        // Muted blue
    success: "#62C499",       // A blend of teal and green to match the new palette.
    error: "#EB6C9D",         // Pink tone for errors, based on the gradient.
    warning: "#F5A982",       // Pinkish-orange warning color.
    lightText: "#af92c2",     // Light purple text color.
    darkText: "#6e5d8c",      // Muted purple tone for dark text.
    darkLine: "#472E63",      // Darker divider line based on purples in the gradient.
    lightLine: "rgba(234,194,223,0.3)",  // Light purple divider.
    darkGradient: "linear-gradient(45deg, rgba(243, 85, 145, 1) 0%, rgba(60, 60, 120, 1) 50%, rgba(35, 60, 140, 1) 100%)",  // Transitioning from pink to blue.
    lightGradient: "linear-gradient(45deg, rgba(243, 175, 205, 1) 0%, rgba(190, 190, 235, 1) 50%, rgba(175, 190, 250, 1) 100%)",  // Lighter version of the darkGradient.
  },
  [ColorThemeNames.AutumnBurst]: {
    name: "Autumn Burst",
    dark: "#242424",
    light: "#EAEAEA",
    accent: "#F57C00",
    success: "#4CAF50",
    error: "#E64A19",
    warning: "#ead000",
    lightText: "#BDBDBD",
    darkText: "#515151",
    darkLine: "#393939",
    lightLine: "rgba(215,215,215,0.3)",
    darkGradient: "linear-gradient(45deg, rgba(36, 24, 50, 1) 0%, rgba(60, 60, 40, 1) 50%, rgba(30, 60, 40, 1) 100%)",
    lightGradient: "linear-gradient(45deg, rgba(200, 180, 210, 1) 0%, rgba(220, 220, 200, 1) 50%, rgba(210, 230, 220, 1) 100%)",
  },
  [ColorThemeNames.StarryNight]: {
    name: "Starry Night",
    dark: "#131525",
    light: "#D7DFF7",
    accent: "#9D50BB",
    success: "#4CB850",
    error: "#F83367",
    warning: "#FFA233",
    lightText: "#A2A4B1",
    darkText: "#575A70",
    darkLine: "#2B2E45",
    lightLine: "rgba(210,213,229,0.3)",
    darkGradient: "linear-gradient(45deg, rgba(15, 15, 40, 1) 0%, rgba(30, 35, 60, 1) 50%, rgba(10, 15, 35, 1) 100%)",
    lightGradient: "linear-gradient(45deg, rgba(180, 185, 205, 1) 0%, rgba(200, 200, 225, 1) 50%, rgba(175, 180, 215, 1) 100%)",
  },
  [ColorThemeNames.Nautical]: {
    name: "Nautical",
    dark: "#2C3E50",
    light: "#ECF0F1",
    accent: "#E74C3C",
    success: "#2ECC71",
    error: "#C0392B",
    warning: "#F39C12",
    lightText: "#ABB7B7",
    darkText: "#657d9a",
    darkLine: "#3a4c57",
    lightLine: "rgba(202,203,211,0.3)",
    darkGradient: "linear-gradient(45deg, rgba(24, 42, 60, 1) 0%, rgba(35, 50, 70, 1) 50%, rgba(20, 40, 65, 1) 100%)",
    lightGradient: "linear-gradient(45deg, rgba(200, 215, 235, 1) 0%, rgba(215, 225, 240, 1) 50%, rgba(195, 210, 235, 1) 100%)",
  },
  [ColorThemeNames.EmeraldTwilight]: {
    name: "Emerald Twilight",
    dark: "#071c2a",
    light: "#D1E9E9",
    accent: "#16b377",
    success: "#2A9D8F",
    error: "#E63946",
    warning: "#F4A261",
    lightText: "#90B8B3",
    darkText: "#4A6D7C",
    darkLine: "#254B5E",
    lightLine: "rgba(175,210,210,0.3)",
    darkGradient: "linear-gradient(45deg, rgba(5, 36, 56, 1) 0%, rgba(10, 48, 68, 1) 50%, rgba(0, 32, 52, 1) 100%)",
    lightGradient: "linear-gradient(45deg, rgba(180, 225, 225, 1) 0%, rgba(195, 235, 235, 1) 50%, rgba(170, 215, 215, 1) 100%)",
  },
};

// Define key for localStorage
const LOCAL_STORAGE_THEME_KEY = 'selectedColorTheme';

// Initialize the selected theme based on what's saved in localStorage.
// Defaults to ClassicBlue if no theme is found in localStorage.
const savedTheme = localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as ColorThemeNames;
let currentTheme: ThemeDefinition;

currentTheme = savedTheme && ColorThemes[savedTheme] ?
  ColorThemes[savedTheme] :
  ColorThemes[ColorThemeNames.ClassicBlue];

// Function to set the theme.
export function setSelectedTheme(themeName: ColorThemeNames) {
  currentTheme = ColorThemes[themeName];
  localStorage.setItem(LOCAL_STORAGE_THEME_KEY, themeName);
}

// Function to get the current theme.
export function getSelectedTheme(): ThemeDefinition {
  return currentTheme;
}
