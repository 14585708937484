// ----- Modules ----- //
import React, { ReactNode, useState } from "react";
import { useConfiguredAxios } from "../Utils/AxiosInstance";

// ----- Utils ----- //
import { IContext, QueryStateT } from "../Utils/Types";

interface DomainsContext extends IContext {}

interface DomainsProviderProps {children: ReactNode;}

const DomainsContext =
  React.createContext<DomainsContext>({
    data: [],
    count: 0,
    fetchData: async () => [],
    isLoading: true,
  });

/**
 * This component is a wrapper for the entire application.
 * It provides the context for the lists.
 * @param children - The children of the component.
 */
const DomainsProvider = ({children}: DomainsProviderProps) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [isLoading, setIsLoading] = useState(true);
  const [domains, setDomains] = useState([]);
  const [count, setCount] = useState(0);

  const fetchDomains = (queryStateT?: QueryStateT) => {
    const {filters, sort, page, pageSize} = queryStateT || {};

    setIsLoading(true);
    const filtersJson = filters?.length ? `&filters=${encodeURIComponent(JSON.stringify(filters))}` : '';
    const sortJson = sort?.length ? `&sort=${encodeURIComponent(JSON.stringify(sort))}` : '';
    axiosInstance.get(`/api/domains?page=${page}&pageSize=${pageSize}` + filtersJson + sortJson)
      .then((response) => {
        const data = response.data;
        setDomains(data.domains);
        setCount(data.total);
        setIsLoading(false);
        return data;
      });
  };

  // ----- Render ----- //
  return (
    <DomainsContext.Provider value={{data: domains, fetchData: fetchDomains, count, isLoading}}>
      {children}
    </DomainsContext.Provider>
  );
};

export { DomainsContext, DomainsProvider };
