// ----- Module ----- //
import React from "react";

// ----- MUI ----- //
import { IconButton, Tooltip } from "@mui/material";
// Icons
import ProxyIcon from '@mui/icons-material/DnsOutlined';
import { getSelectedTheme } from "../../Utils/Colors";

// ----- Components ----- //
// ----- Utils ----- //

/**
 * Modal for editing or creating an account
 * - edit: whether the modal is for editing or creating an account
 * - account: the account to edit, if edit is true
 */
const Proxy = () => {
  // ----- Render ----- //
  return (
    <>
      <Tooltip title={'Not Implemented Yet'} placement={'top'}>
        <IconButton aria-label="Show Proxy Info" component="span"
                    sx={{p: 0.25, position: 'relative', left: '-7px'}}>
          <ProxyIcon sx={{color: getSelectedTheme().darkText, fontSize: '40px'}}/>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default Proxy;
