// ----- Modules ----- //
import React, { useEffect } from 'react';

// ----- MUI ----- //
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// ----- Providers ----- //
import { closeSnackbar, enqueueSnackbar, SnackbarProvider } from "notistack";
import { AccountsProvider } from "./AccountsProvider";
import { ListsProvider } from "./ListsProvider";
import { CreditCardsProvider } from "./CreditCardsProvider";
import { ProxiesProvider } from "./ProxiesProvider";
import { DomainsProvider } from "./DomainsProvider";
import { StatusProvider } from "./StatusProvider";
import { NavProvider } from "./NavProvider";
import WebSocketManager from "../services/WebSocketManager";

// ----- Utils ----- //

const ProvidersWrapper = ({children}: { children: React.ReactNode }) => {

  useEffect(() => {
    const handleWebSocketMessage = (message: any) => {
      if (message.type === 'BROADCAST')
        enqueueSnackbar(message.data.message, {variant: message.data.variant, autoHideDuration: null});
    };

    const webSocketManager = new WebSocketManager(handleWebSocketMessage);
    return () => {
      webSocketManager.disconnect();
    };
  }, []);

  const snackbarAction = (snackbarId: any) => (
    <IconButton onClick={() => closeSnackbar(snackbarId)}>
      <CloseIcon/>
    </IconButton>
  );

  return (
    <SnackbarProvider
      action={snackbarAction}
      dense
      maxSnack={3}
      preventDuplicate
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <AccountsProvider>
        <ListsProvider>
          <CreditCardsProvider>
            <ProxiesProvider>
              <DomainsProvider>
                <StatusProvider>
                  <NavProvider>
                    {children}
                  </NavProvider>
                </StatusProvider>
              </DomainsProvider>
            </ProxiesProvider>
          </CreditCardsProvider>
        </ListsProvider>
      </AccountsProvider>
    </SnackbarProvider>
  );
};

export default ProvidersWrapper;
