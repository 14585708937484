// ----- Modules ----- //
import React from "react";

// ----- MUI ----- //
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const WorkingSelect = (props: {
  onChange: any,
  disabled?: boolean,
}) => {
  const {onChange, disabled} = props;

  const [working_, setWorking] = React.useState(0);

  return (
    <FormControl fullWidth>
      <InputLabel id="working-select">Working?</InputLabel>
      <Select
        id={'working-select'}
        size={'small'}
        value={working_}
        label="Working?"
        onChange={(e) => {
          setWorking(parseInt(e.target.value + ''));
          onChange(e, parseInt(e.target.value + ''));
        }}
        disabled={disabled}
      >
        <MenuItem value={0}>Not Working</MenuItem>
        <MenuItem value={1}>Working</MenuItem>
      </Select>
    </FormControl>
  );
};

export default WorkingSelect;
