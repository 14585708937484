import { getSelectedTheme } from "../../Colors";

export default function ThemedScrollbar(dark: boolean) {
  const SelectedTheme = getSelectedTheme();

  const trackColor = dark ? SelectedTheme.dark : SelectedTheme.light;
  const thumbColor = SelectedTheme.accent;
  const activeColor = SelectedTheme.accent;

  return {
    scrollbarColor: `${thumbColor} ${trackColor}`,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: trackColor,
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: thumbColor,
      minHeight: 24,
      border: `4px solid ${trackColor}`,
    },
    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
      backgroundColor: activeColor,
    },
    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
      backgroundColor: activeColor,
    },
    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
      backgroundColor: activeColor,
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: trackColor,
    },
  };
}
