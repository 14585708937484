import React from "react";
import { ColorThemeNames, ColorThemes, getSelectedTheme, setSelectedTheme } from "../../../Utils/Colors";
import { Box, Tooltip } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import useMediaQuery from "@mui/material/useMediaQuery";

const ColorThemeSelector = () => {
  // const theme = useTheme();

  const selectedThemeInfo = getSelectedTheme();
  const selectedThemeName = selectedThemeInfo.name;

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  // Handle theme selection change
  const handleThemeChange = (themeName: ColorThemeNames) => {
    if (selectedThemeName === themeName) return;
    setSelectedTheme(themeName);
    window.location.reload();
  };

  return (
    <>
      {/*<IconButton sx={{mr: 1}} onClick={() => console.log('hehe')}>*/}
      {/*  {theme.palette.mode === 'dark' ? <DarkModeIcon/> : <LightModeIcon/>}*/}
      {/*</IconButton>*/}
      <Box style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
        {Object.entries(ColorThemes).map(([key, theme]) => (
          <Tooltip title={theme.name} placement={'top'} arrow key={key}>
            <Box
              key={key}
              onClick={() => handleThemeChange(key as ColorThemeNames)}
              sx={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                cursor: selectedThemeInfo.name === key ? 'default' : 'pointer',
                backgroundImage: prefersDarkMode ? theme.darkGradient : theme.lightGradient,
                border: `3px solid ${theme.accent}`,
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              title={theme.name}
            >
              {selectedThemeName === key &&
                <CheckIcon sx={{
                  color: getSelectedTheme().success,
                  fontSize: '40px',
                  fontWeight: 'bold',
                }}/>
              }
            </Box>
          </Tooltip>
        ))}
      </Box>
    </>
  );
};

export default ColorThemeSelector;
