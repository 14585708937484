// ----- Modules ----- //
import React, { ReactNode, useState } from "react";

// ----- Utils ----- //
import { IContext, ProxyT, QueryStateT } from "../Utils/Types";
import { useConfiguredAxios } from "../Utils/AxiosInstance";

interface ProxiesContext extends IContext {
  getAvailableProxies: (accountType?: string) => Promise<ProxyT[]>;
}

interface ProxiesProviderProps {children: ReactNode;}

const ProxiesContext = React.createContext<ProxiesContext>({
  data: [],
  count: 0,
  fetchData: async () => [],
  isLoading: true,
  getAvailableProxies: () => Promise.resolve([])
});

/**
 * This component is a wrapper for the entire application.
 * It provides the context for the proxies.
 * @param children - The children of the component.
 */
const ProxiesProvider = ({children}: ProxiesProviderProps) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [isLoading, setIsLoading] = useState(true);
  const [proxies, setProxies] = useState([] as ProxyT[]);
  const [count, setCount] = useState(0);

  const fetchProxies = async (queryStateT?: QueryStateT) => {
    const {filters, sort, page, pageSize} = queryStateT || {};
    setIsLoading(true);
    const filtersJson = filters?.length ? `&filters=${encodeURIComponent(JSON.stringify(filters))}` : '';
    const sortJson = sort?.length ? `&sort=${encodeURIComponent(JSON.stringify(sort))}` : '';
    await axiosInstance.get(`/api/proxies?page=${page}&pageSize=${pageSize}` + filtersJson + sortJson)
      .then((response) => {
        const data = response.data;
        setProxies(data.proxies);
        setCount(data.total);
        setIsLoading(false);
      });
  };

  // ----- Functions ----- //
  const getAvailableProxies = async (accountType?: string) => {
    return await axiosInstance.get(`/api/proxies/available?account_type=${accountType || ''}`)
      .then((response) => {
        return response.data;
      });
  };

  // ----- Render ----- //
  return (
    <ProxiesContext.Provider
      value={{data: proxies, fetchData: fetchProxies, count, isLoading, getAvailableProxies}}>
      {children}
    </ProxiesContext.Provider>
  );
};

export { ProxiesContext, ProxiesProvider };
