// ----- Modules ----- //
import React, { useEffect, useState } from "react";

// ----- MUI ----- //
import { Box, Card, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import { getSelectedTheme } from "../Utils/Colors";
import { FONTS } from "../index";

// ----- Utils ----- //

const logo = require('../assets/logo.png');

const Error = (props: { error: boolean }) => {
  const {error} = props;
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    // Refresh the page when countdown reaches 0
    if (countdown === 0) {
      window.location.reload();
    }

    if (!error) clearInterval(interval);
    return () => clearInterval(interval);
  }, [countdown, error]);

  return error ? (
    <Box height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Card>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
          backgroundColor: getSelectedTheme().dark,
          p: 1,
          mb: 2
        }}>
          <img src={logo} alt="Messi Logo" style={{height: '50px', marginBottom: 2}}/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', px: 3}}>
          <ErrorIcon sx={{fontSize: 60, color: getSelectedTheme().error, marginBottom: 1}}/>

          <Typography variant="h3" sx={{
            color: getSelectedTheme().error, marginBottom: 1, fontWeight: 'bold',
            fontFamily: FONTS
          }}>
            ERROR
          </Typography>

          <Typography variant="body1" sx={{marginBottom: 1}}>
            Failed to communicate with the server.
          </Typography>
          <Typography variant="body2" sx={{marginBottom: 3}}>
            Please try again later. If the problem persists, contact an administrator.
          </Typography>
        </Box>

        <Box sx={{
          display: 'flex', justifyContent: 'center', width: '100%',
          backgroundColor: getSelectedTheme().dark, p: 1
        }}>
          <Typography variant="body1" color={getSelectedTheme().lightText}>
            Retrying in {countdown} seconds...
          </Typography>
        </Box>
      </Card>
    </Box>
  ) : null;
};


export default Error;
