import { Autocomplete, CircularProgress, debounce, TextField } from "@mui/material";
import { ListT } from "../../Utils/Types";
import React, { useEffect } from "react";
import { useConfiguredAxios } from "../../Utils/AxiosInstance";

const ListsSelect = (props: {
  default_lists: ListT[],
  onChange: any,
  disabled?: boolean,
  single?: boolean,
}) => {
  const axiosInstance = useConfiguredAxios();

  const {default_lists, onChange, disabled, single} = props;

  const [listsInput, setListsInput] = React.useState<string>('');
  const [lists, setLists] = React.useState<ListT[]>([]);
  const [loadLists, setLoadLists] = React.useState(false);

  // ----- Functions ----- //
  const fetchLists = async (input: string) => {
    if (!input) return setLists([]);
    setLoadLists(true);
    const result = await axiosInstance.get(`/api/lists?name=${input}`);
    setLists(result.data);
    setLoadLists(false);
  };

  useEffect(() => {
    const debouncedFetch = debounce(fetchLists, 250);
    debouncedFetch(listsInput);
    return () => debouncedFetch.clear();
  }, [listsInput]);

  return (
    <Autocomplete
      id="account-lists"
      size={'small'}

      disabled={disabled}
      multiple={!single}

      autoHighlight
      options={lists || null}
      fullWidth

      defaultValue={single ? default_lists[0] : default_lists}
      getOptionLabel={(option: any) => option.name}
      renderInput={(params) =>
        <TextField {...params} label="Lists" variant="outlined" size={'small'}
                   InputProps={{
                     ...params.InputProps,
                     endAdornment: (
                       <React.Fragment>
                         {loadLists ? <CircularProgress color="inherit" size={20}/> : null}
                         {params.InputProps.endAdornment}
                       </React.Fragment>
                     ),
                   }}
        />}

      onChange={onChange}
      onInputChange={(e, value) => {
        setListsInput(value);
      }}

      noOptionsText={listsInput && !loadLists ? 'No lists found' : 'Type to search'}
      filterOptions={(x) => x}
      loading={loadLists}
    />
  );
};

export default ListsSelect;
