// ----- Modules ----- //
import { useState } from "react";
import { enqueueSnackbar } from "notistack";

// ----- MUI ----- //
import { Box, Button } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import DomainIcon from '@mui/icons-material/Domain';

// ----- Components ----- //
import WorkingSelect from "../../../inputs/WorkingSelect";

// ----- Utils ----- //
import { ListT } from "../../../../Utils/Types";
import { useConfiguredAxios } from "../../../../Utils/AxiosInstance";
import DomainsSelect from "../../../inputs/DomainsSelect";

const Domains = () => {
  const axiosInstance = useConfiguredAxios();

  const [domains, setDomains] = useState<ListT[]>([]);
  const [working, setWorking] = useState<boolean>(false);

  const HandleUpdate = async () => {
    await axiosInstance.post('/api/domains/working'
      , {
        domains: domains.map(group => group.id),
        working: working
      })

      .then((res) => {
        setDomains(res.data);
        enqueueSnackbar('Updated Successfully', {variant: 'success'});
      })
      .catch(() => {
        enqueueSnackbar('Failed to update', {variant: 'error'});
      });

    setWorking(false);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, width: '100%'}}>

      <Box sx={{display: 'flex', gap: 1, alignItems: 'center', width: '100%'}}>
        <DomainIcon sx={{fontSize: '35px'}}/>

        <DomainsSelect
          onChange={(_: any, value: ListT[]) => {
            setDomains(value);
          }}/>
      </Box>

      <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <CheckIcon sx={{fontSize: '35px'}}/>

        <WorkingSelect
          onChange={(_: any, value: boolean) => {
            setWorking(value);
          }}
        />
        
        <Button
          disabled={domains.length === 0}
          onClick={HandleUpdate}
          variant={'contained'}
          color={'primary'}
        >
          Update
        </Button>

      </Box>
    </Box>
  );
};

export default Domains;
