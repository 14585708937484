import * as React from 'react';
// @ts-ignore
import * as ReactDOM from 'react-dom/client';
import App from "./App";

import "./index.css";
import './assets/fonts.css';

import { LicenseInfo } from "@mui/x-license";
import LogRocket from "logrocket";
import setupLogRocketReact from 'logrocket-react';
import WebSocketService from "./services/WebSocketService";

// License KEY for MUI PREMIUM components
LicenseInfo.setLicenseKey(
  "0b18f155ab2adb3b1055055465bf04b4Tz03Mzk1NCxFPTE3MjUyODg1ODUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

WebSocketService.init();

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

export const FONTS = 'Teko, Verdana, Tahoma, Trebuchet MS, sans-serif';

LogRocket.init('imngox/messi-frontend');
setupLogRocketReact(LogRocket);

root.render(
  // <React.StrictMode>
  <App/>
  //*</React.StrictMode>
);
