import React from "react";
import { Box } from "@mui/material";

const Template = (props: { slot_1: any, slot_2: any, slot_3: any, slot_4: any }) => {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', width: '100%', gap: 2}}>
      <Box sx={{width: 3 / 12}}>
        {props.slot_1}
      </Box>
      <Box sx={{width: 4 / 12}}>
        {props.slot_2}
      </Box>
      <Box sx={{width: 3 / 12}}>
        {props.slot_3}
      </Box>
      <Box sx={{width: 2 / 12}}>
        {props.slot_4}
      </Box>
    </Box>
  );
};

export default Template;
