// ----- Modules ----- //
import React from "react";

// ----- MUI ----- //
import { Box, ButtonBase, Divider, IconButton, Modal, Tab, Tabs, Typography } from "@mui/material";
import ToolsIcon from '@mui/icons-material/HandymanOutlined';
import BalanceIcon from '@mui/icons-material/AccountBalance';
import ColorThemeIcon from '@mui/icons-material/ColorLens';

// ----- Components ----- //
import Title from "../components/Title";
import ExportImport from "./ExportImport";
import BulkStatusIndex from "./bulkStatus/Index";
import AccountsBalance from "./AccountsBalance";
import ColorThemeSelector from "./ColorThemeSelector";

// ----- Utils ----- //
import { ModalStyle } from "../../../Utils/Theme/Theme";
import { getSelectedTheme } from "../../../Utils/Colors";
import { FONTS } from "../../../index";

const Index = () => {
  const [open, setOpen] = React.useState(false);

  const [tab, setTab] = React.useState(0);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const TabPanel = (props: { children: React.ReactNode, value: number, index: number }) => (
    <Box hidden={props.value !== props.index} sx={{width: '100%'}}>
      {props.value === props.index && (
        <Box sx={{
          display: 'flex',
          mt: 2,
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          px: 1,
          height: '300px'
        }}>
          {props.children}
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <ButtonBase
        onClick={handleOpen}
        sx={{display: 'flex', alignItems: 'center', height: '100%', pr: 0.5}}
      >
        <IconButton size={'large'} sx={{color: getSelectedTheme().accent, p: 1}}>
          <ToolsIcon fontSize={'large'}/>
        </IconButton>

        <Typography fontFamily={FONTS} fontSize={24}
                    sx={{color: getSelectedTheme().darkText, position: 'relative', bottom: '-7px'}}>
          Tools
        </Typography>

      </ButtonBase>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="tools-modal-title"
        aria-describedby="tools-modal-description"
        style={{backdropFilter: open ? 'blur(2px)' : 'blur(0px)'}}
        keepMounted
      >
        <Box sx={{...ModalStyle, pb: 4}}>
          <Title title={'TOOLS'}
                 subtitle={'New interface, work in progress!'}
                 icon={<ToolsIcon sx={{fontSize: '35px'}}/>} close={handleClose}/>

          <Divider/>

          <Tabs value={tab}
                onChange={(_, value) => setTab(value)}
          >
            <Tab label="Export/Import"/>
            <Tab label="Bulk status"/>
            <Tab label="Balances"/>
            <Tab label="Settings"/>
          </Tabs>

          <TabPanel value={tab} index={0}>
            <ExportImport/>
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <BulkStatusIndex/>
          </TabPanel>

          <TabPanel value={tab} index={2}>
            <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
              {ToolTitle({
                title: 'Accounts Balance',
                icon: <BalanceIcon sx={{fontSize: '35px'}}/>
              })}
              <AccountsBalance/>
            </Box>
          </TabPanel>

          <TabPanel value={tab} index={3}>
            <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
              {ToolTitle({
                title: 'App Theme',
                icon: <ColorThemeIcon sx={{fontSize: '35px'}}/>
              })}
              <ColorThemeSelector/>
            </Box>
          </TabPanel>
        </Box>
      </Modal>
    </>
  );
};

export const ToolTitle = (props: { title: string, icon: any }) => (
  <Box sx={{display: 'flex', alignItems: 'center', width: '250px'}}>
    {props.icon}
    <Typography sx={{position: 'relative', top: '3px', ml: 0.5, lineHeight: 0.8}}
                variant={'h6'} fontFamily={FONTS}
                color={getSelectedTheme().lightText}>
      {props.title.split(' ').map((word, index) => (
        <React.Fragment key={index}>
          {word}
          <br/>
        </React.Fragment>
      ))}
    </Typography>
  </Box>
);

export default Index;
