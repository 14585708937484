// ----- Modules ----- //
import React, { useCallback } from "react";
import { useSnackbar } from "notistack";

// ----- MUI ----- //
import { Box, Button, ButtonGroup, CircularProgress, Tooltip, Typography } from "@mui/material";
// Icons
import TemplateIcon from '@mui/icons-material/DescriptionOutlined';
import UploadIcon from '@mui/icons-material/DriveFolderUpload';

// ----- Components ----- //
import Template from "../Template";

// ----- Utils ----- //
import { useConfiguredAxios } from "../../../../Utils/AxiosInstance";
import { CreditCardsContext } from "../../../../contexts/CreditCardsProvider";
import { getSelectedTheme } from "../../../../Utils/Colors";
import { FONTS } from "../../../../index";

enum COLUMNS {
  'TYPE',
  'NUMBER',
  'CVV',
  'EXP',
  'CURRENCY',
  'SAFEKEY',
}

const ImportCreditCards = () => {
  const axiosInstance = useConfiguredAxios();

  // ----- Context ----- //
  const {enqueueSnackbar} = useSnackbar();
  const {types} = React.useContext(CreditCardsContext);


  const [file, setFile] = React.useState<File | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleUpload = useCallback(() => {
    if (!file) {
      enqueueSnackbar('No file selected', {variant: 'error'});
      return;
    }
    setLoading(true);
    formatFile(file);
  }, [file]);

  const formatFile = (file: File) => {
    const reader = new FileReader();

    reader.readAsText(file);

    reader.onload = async function () {
      const lines = reader.result?.toString().split('\n');

      if (!lines) {
        enqueueSnackbar('Invalid file', {variant: 'error'});
        return;
      }

      lines?.shift();

      const creditCards = [] as any[];

      for (const line of lines) {
        const creditCard = line.split(',') as string[];

        creditCards.push({
          type_id: types.find((t) => t.name.toLowerCase() === creditCard[COLUMNS.TYPE].toLowerCase())?.id,
          number: creditCard[COLUMNS.NUMBER],
          cvv: creditCard[COLUMNS.CVV],
          exp: creditCard[COLUMNS.EXP],
          currency: creditCard[COLUMNS.CURRENCY],
          safekey: creditCard[COLUMNS.SAFEKEY],
        });
      }

      enqueueSnackbar(`Uploading ${creditCards.length} credit card(s)...`, {variant: 'info'});

      await uploadCreditCards(creditCards);

      setLoading(false);
      setFile(null);
    };
  };

  const uploadCreditCards = async (credit_cards: any[]) => {
    try {
      await axiosInstance.post('/api/creditcards/import', credit_cards)
        .then((res) => {
          if (!res.data) return;
          enqueueSnackbar(`Successfully uploaded ${credit_cards.length} credit card(s)!`, {variant: 'success'});
        });
    } catch (e) {
      enqueueSnackbar('Failed to upload credit cards', {variant: 'error'});
      return;
    }
  };

  const input = (
    <Box sx={{position: 'relative'}}>
      <Typography
        sx={{position: 'absolute', color: getSelectedTheme().lightText, bottom: -20, whiteSpace: 'nowrap'}}
        fontFamily={FONTS} fontSize={14}>
        {file ? file.name : 'No file selected'}
      </Typography>

      <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{width: '100%'}}>
        <Tooltip title={'Open Template'} placement={'top'} arrow>
          <Button href={'https://docs.google.com/spreadsheets/d/1qHz1q2XPZtWflGS6xibjTYnjPYidFHXn4j1PkViNxA8/edit'}
                  target={'_blank'} sx={{borderTopRightRadius: 0, borderBottomRightRadius: 0, width: 20}}
                  fullWidth>
            <TemplateIcon/>
          </Button>
        </Tooltip>
        <Tooltip title={'.CSV Only'} placement={'top'} arrow>
          <Button component="label" sx={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} endIcon={<UploadIcon/>}
                  fullWidth>
            <Typography sx={{pt: 0.5}} fontFamily={FONTS} fontSize={20}>
              Upload
            </Typography>
            <input type="file" accept=".csv" hidden onChange={(e) => setFile(e.target.files?.[0] ?? null)}/>
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );

  const info = (<></>);
  const type = (<></>);

  const button = (
    loading ? <Box sx={{px: 0.97}}><CircularProgress size={25}/></Box> :
      <Tooltip title={'Upload Accounts From File'} placement={'top'} arrow>
        <Button
          variant="contained"
          onClick={() => handleUpload()}
          disabled={!file || loading}
        >
          IMPORT
        </Button>
      </Tooltip>
  );

  return (
    <Template slot_1={info} slot_2={input} slot_3={type} slot_4={button}/>
  );
};

export default ImportCreditCards;
