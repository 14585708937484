// ----- Module ----- //
import React, { memo } from "react";

// ----- MUI ----- //
import { Box, Typography } from "@mui/material";

// ----- Utils ----- //
import splash_texts from "../assets/splash_texts";

// ----- Components ----- //
import Navigation from "./Navigation";
import IndexTools from "./modal/tools/Index";
import { getSelectedTheme } from "../Utils/Colors";

// ----- Utils ----- //
import { FONTS } from "../index";

const logo = require('../assets/logo.png');

/**
 * Main Page of the app. Displays a list of accounts.
 */
export default memo(function Header() {

  // ----- Render ----- //
  return (
    <Box sx={{
      bgcolor: getSelectedTheme().dark,
      color: getSelectedTheme().lightText,
      py: 1.5,
      boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.25)'
    }}>
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%', mx: '100px'}}>

        <Box sx={{display: 'flex', alignItems: 'center', width: '175px'}}>
          <img src={logo} alt="Messi Logo" style={{height: '50px'}}/>
          <Typography sx={{position: 'relative', left: '-70px', bottom: '-22px', whiteSpace: 'nowrap'}} fontSize={15}
                      fontFamily={FONTS}>
            {splash_texts[Math.floor(Math.random() * splash_texts.length)]}
          </Typography>
        </Box>

        <Navigation/>

        <Box sx={{bgcolor: getSelectedTheme().darkLine, height: '3px', width: '100%'}}/>

        <IndexTools/>
      </Box>
    </Box>
  );
});
