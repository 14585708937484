import React from "react";
import { createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { getSelectedTheme } from "../Colors";
import ThemedScrollbar from "./Components/Scrollbar";
import { FONTS } from "../../index";

export default function DefaultTheme() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            main: getSelectedTheme().accent,
          },
          action: {
            disabledBackground: prefersDarkMode ? getSelectedTheme().dark : getSelectedTheme().light,
          },
          background: {
            default: prefersDarkMode ? getSelectedTheme().dark : getSelectedTheme().light,
            paper: prefersDarkMode ? getSelectedTheme().dark : getSelectedTheme().light,
          },
          error: {
            main: getSelectedTheme().error,
          },
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: (themeParam) => ({
              body: ThemedScrollbar(prefersDarkMode),

              // This is the style for the background of the page
              "#root": {
                background: themeParam.palette.mode !== "dark" ? getSelectedTheme().lightGradient
                  : getSelectedTheme().darkGradient,
                backgroundSize: '175% 175%',
                animation: 'gradientAnimation 20s infinite linear',
              },

              // This is the style for the paper elevation element
              '#content': {
                backgroundColor: themeParam.palette.mode !== "dark" ? 'rgba(255,255,255,0.5) !important'
                  : 'rgba(0,0,0,0.5) !important',
              },

              // This is the style for the label of the textfield
              "& .MuiFormLabel-root": {
                fontFamily: FONTS + " !important",
              },
              // This is the style for the label of the checkbox
              "& .MuiFormControlLabel-label": {
                fontFamily: FONTS + " !important",
                fontSize: "20px !important",
              },
              // This is the style for the buttons
              "& .MuiButton-root": {
                fontFamily: FONTS + " !important",
                fontSize: "20px !important",
                padding: "0 !important",
              },
              // This is the style for the datagrid header
              "& .MuiDataGrid-columnHeaderTitle": {
                fontFamily: FONTS + " !important",
                fontSize: "18px !important",
                textTransform: "uppercase",
              },
              // This is the style for the dialog element
              "& .MuiDialogTitle-root": {
                fontFamily: FONTS + " !important",
                fontSize: "30px !important",
                padding: '10px 20px !important',
              },
              "& .MuiDialogContent-root": {
                paddingBottom: '10px !important',
              },

              // This is the style for the line under the textfield
              "& .MuiInput-underline:before": {
                borderBottomColor: getSelectedTheme().lightLine + " !important",
              },

              // This is the style for the icon the dropdown select
              "& .MuiSelect-icon": {
                color: getSelectedTheme().lightText + " !important",
              },
            })
          }
        },
      }),
    [prefersDarkMode]
  );
}

const DarkTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: getSelectedTheme().lightText,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: getSelectedTheme().lightText,
    color: getSelectedTheme().lightText,
  },
});

const DarkDataGridPremium = styled(DataGridPremium)({
  '& .MuiDataGrid-columnHeaders > div': {
    background: getSelectedTheme().dark + ' !important',
    color: 'white',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-filler--borderTop': {
    borderColor: getSelectedTheme().darkLine + ' !important',
  },
  '& .MuiFormLabel-root': {
    color: getSelectedTheme().lightText,
    opacity: 0.5,
    lineHeight: 3,
    marginBottom: '10px',
    fontSize: '18px',
    top: '-3px',
  },
  '& .MuiButtonBase-root': {
    color: getSelectedTheme().lightText,
    padding: '3px',
    margin: '0px 5px',
  },
  '& .MuiInputBase-input': {
    color: 'white',
    fontSize: '12px',
    height: '15px',
  },
  '& .MuiSelect-select': {
    height: '10px !important',
    paddingBottom: '2px !important',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: getSelectedTheme().accent,
  },
  '& .MuiInput-underline.Mui-error:after': {
    borderBottomColor: getSelectedTheme().error,
  },
  '& .MuiDataGrid-headerFilterRow': {
    borderColor: getSelectedTheme().darkLine,
  },
  '& .MuiInputBase-root': {
    marginTop: '0px',
  },
  '& .MuiFormLabel-root ': {
    transform: 'translate(0, -13px) scale(0.75)',
  },
  '& .MuiDataGrid-main': {
    borderTopLeftRadius: '7px',
    borderTopRightRadius: '7px',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderColor: getSelectedTheme().lightLine,
  },
  '& .MuiDataGrid-scrollbarFiller': {
    borderTop: 0 + ' !important',
  },
  '& .MuiDataGrid-footerContainer': {
    border: 0,
    minHeight: '40px',
    maxHeight: '40px',
    backgroundColor: getSelectedTheme().dark,
    borderBottomLeftRadius: '7px',
    borderBottomRightRadius: '7px',
  },
  '& .MuiTablePagination-root': {
    color: getSelectedTheme().lightText,
    fontSize: '20px',
  },
  ".row-even": {backgroundColor: "rgba(0,0,0,0)"},
  ".row-odd": {backgroundColor: "rgba(125,125,125,0.05)"},
  border: 0,
});

const ModalStyle = {
  overflow: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "7px",
  boxShadow: 24,
  maxHeight: "90%",
  px: 4,
  pb: 2,
};

export { DarkTextField, DarkDataGridPremium, ModalStyle };
