// ----- Modules ----- //
import React from "react";

// ----- MUI ----- //
import { Box, IconButton } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { getSelectedTheme } from "../../Utils/Colors";

const CellCopy = (props: { value: string }) => {
  const {value} = props;

  const [copied, setCopied] = React.useState(false);

  const icon = copied ? <CheckIcon sx={{fontSize: '15px', color: getSelectedTheme().success}}/>
    : <ContentCopyIcon sx={{fontSize: '15px'}}/>;

  return value && (
    <Box className={'MuiDataGrid-cellContent'}
         sx={{cursor: 'pointer'}} onClick={() => {
      navigator.clipboard.writeText(value).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      });
    }}>
      <IconButton size={'small'}>
        {icon}
      </IconButton>
      {value}
    </Box>
  ) || null;
};

export default CellCopy;
