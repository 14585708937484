// ----- Modules ----- //
import { useState } from "react";

// ----- MUI ----- //
import { Box, Button } from "@mui/material";
import SubnetIcon from '@mui/icons-material/Dns';
import CheckIcon from '@mui/icons-material/Check';

// ----- Components ----- //
import WorkingSelect from "../../../inputs/WorkingSelect";
import SubnetsSelect from "../../../inputs/SubnetsSelect";

// ----- Utils ----- //
import { ListT } from "../../../../Utils/Types";
import { useConfiguredAxios } from "../../../../Utils/AxiosInstance";
import { enqueueSnackbar } from "notistack";

const Subnets = () => {
  const axiosInstance = useConfiguredAxios();

  const [groups, setGroups] = useState<ListT[]>([]);
  const [working, setWorking] = useState<boolean>(false);

  const HandleUpdate = async () => {
    await axiosInstance.post('/api/proxies/groups/working'
      , {
        groups: groups.map(group => group.id),
        working: working
      })
      .then(() => {
        enqueueSnackbar('Updated Successfully', {variant: 'success'});
      })
      .catch(() => {
        enqueueSnackbar('Failed to update', {variant: 'error'});
      });

    setWorking(false);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, width: '100%'}}>

      <Box sx={{display: 'flex', gap: 1, alignItems: 'center', width: '100%'}}>
        <SubnetIcon sx={{fontSize: '35px'}}/>

        <SubnetsSelect onChange={(_: any, value: ListT[]) => {
          setGroups(value);
        }}/>
      </Box>

      <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <CheckIcon sx={{fontSize: '35px'}}/>

        <WorkingSelect
          onChange={(_: any, value: boolean) => {
            setWorking(value);
          }}
        />

        <Button
          disabled={groups.length === 0}
          onClick={HandleUpdate}
          variant={'contained'}
          color={'primary'}
        >
          Update
        </Button>

      </Box>
    </Box>
  );
};

export default Subnets;
