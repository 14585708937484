// ----- Modules ----- //
import React, { useState } from "react";

// ----- MUI ----- //
import { Badge, BadgeProps, Box, Divider, Grid, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import PeopleIcon from '@mui/icons-material/PeopleOutline';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import ListIcon from '@mui/icons-material/FormatListBulleted';
import styled from "@emotion/styled";

// ----- Components ----- //
import Title from "./components/Title";

// ----- Utils ----- //
import { getSelectedTheme } from "../../Utils/Colors";
import { AccountT } from "../../Utils/Types";
import { ModalStyle } from "../../Utils/Theme/Theme";

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 10,
  },
}));

const ProxyAccounts = (props: { accounts: AccountT[] }) => {
  const {accounts} = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton component="span" onClick={() => handleOpen()}
                  disabled={accounts.length === 0}
                  sx={{p: 0}}>
        <StyledBadge badgeContent={accounts.length}>
          <PeopleIcon sx={{
            color: accounts.length === 0 ? getSelectedTheme().darkText : getSelectedTheme().accent,
            fontSize: '30px'
          }}/>
        </StyledBadge>
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        style={{backdropFilter: "blur(2px)"}}
      >
        <Box sx={ModalStyle}>
          <Title title={'ACCOUNTS'} icon={<PeopleIcon sx={{fontSize: '35px'}}/>}/>

          <Divider/>

          <Box sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 1, maxHeight: 500, overflowY: 'auto'}}>
            {accounts.map((account: AccountT) => {
                return (
                  <>
                    <Grid container xs={12}>
                      <Grid item xs={8}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
                          <Tooltip title={'Email'} placement={'top'} arrow>
                            <EmailIcon sx={{color: getSelectedTheme().darkText}}/>
                          </Tooltip>
                          {account.email}
                        </Box>
                      </Grid>
                      <Grid item xs={12} ml={2}>
                        <Typography
                          sx={{color: getSelectedTheme().darkText, display: 'flex', alignItems: 'center', gap: 0.5}}
                          variant={'caption'}>
                          <Tooltip title={'Lists'} placement={'top'} arrow>
                            <ListIcon fontSize={'small'}/>
                          </Tooltip>
                          {account.lists?.map((list) => list.name).join(', ')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                );
              }
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ProxyAccounts;
