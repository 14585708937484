import { Autocomplete, FormControl, TextField } from "@mui/material";
import React, { useContext } from "react";
import { CreditCardsContext } from "../../contexts/CreditCardsProvider";

const CCTypeSelect = (props: {
  onChange: any,
  disabled?: boolean,
}) => {
  const {types} = useContext(CreditCardsContext);
  const {onChange} = props;

  const [ccType, setCcType] = React.useState({} as any);

  return (
    <FormControl size="small" sx={{width: '100%'}}>
      <Autocomplete
        id="cc-type-autocomplete"
        options={types}
        getOptionLabel={(option) => option.name || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            label="CC Type"
            size="small"
          />
        )}
        value={ccType}
        onChange={(e, value) => {
          setCcType(value);
          onChange(value);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        size="small"
      />
    </FormControl>
  );
};

export default CCTypeSelect;
