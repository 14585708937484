// ----- Module ----- //
import React, { useCallback, useContext } from "react";
import { useSnackbar } from "notistack";

// ----- MUI ----- //
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Modal,
  Switch,
  TextField,
  Tooltip
} from "@mui/material";
// Icons
import EditIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CreateListIcon from '@mui/icons-material/PlaylistAdd';

// ----- Components ----- //
import Title from "./components/Title";

// ----- Utils ----- //
import { ListT } from "../../Utils/Types";
import { useConfiguredAxios } from "../../Utils/AxiosInstance";
import { ListsContext } from "../../contexts/ListsProvider";
import { ModalStyle } from "../../Utils/Theme/Theme";
import { getSelectedTheme } from "../../Utils/Colors";
import ListIcon from "@mui/icons-material/FormatListBulleted";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";

/**
 * Modal for editing or creating a Domain
 */
const List = (props: { edit?: boolean, list?: ListT }) => {
  const axiosInstance = useConfiguredAxios();

  const {edit, list} = props;

  const {handleListEdit, handleListCreate} = useContext(ListsContext);
  const {enqueueSnackbar} = useSnackbar();

  const [listInfo, setListInfo] = React.useState<ListT>(list || {} as ListT);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // ----- Functions ----- //
  const handleOpen = () => {
    setListInfo(list || {} as ListT);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setListInfo({} as ListT);
  };

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    switch (true) {
      case edit:
        axiosInstance.put(`/api/lists/${listInfo?.id}/`, listInfo)
          .then(res => {
            handleListEdit(res.data);
            enqueueSnackbar('List updated successfully!', {variant: 'success'});
            handleClose();
          })
          .catch(() => {
            enqueueSnackbar('Failed to update list.', {variant: 'error'});
          })
          .finally(() => setIsLoading(false));
        break;
      default:
        axiosInstance.post('/api/lists/', listInfo)
          .then(res => {
            handleListCreate(res.data);
            enqueueSnackbar('List created successfully!', {variant: 'success'});
            handleClose();
          })
          .catch(() => {
            enqueueSnackbar('Failed to create list.', {variant: 'error'});
          })
          .finally(() => setIsLoading(false));
        break;
    }
  }, [listInfo]);

  const handleListInfoChange = (key: string, value: string | number | {}) => {
    if (!listInfo) return;
    setListInfo({...listInfo, [key]: value});
  };

  // ----- Render ----- //
  return (
    <>
      <IconButton aria-label="Edit List" component="span"
                  sx={{p: 0.25, position: edit ? 'relative' : '', left: '-7px'}}
                  onClick={handleOpen}>
        {edit ?
          <EditIcon sx={{color: getSelectedTheme().accent, fontSize: '30px'}}/> :
          <CreateListIcon sx={{color: getSelectedTheme().accent, fontSize: '40px'}}/>
        }
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="cc-modal-title"
        aria-describedby="cc-modal-description"
        style={{backdropFilter: "blur(2px)"}}
      >
        <Box sx={ModalStyle}>
          <Title title={(edit ? 'EDIT' : 'CREATE') + ' LIST'} icon={<EditIcon sx={{fontSize: '35px'}}/>}/>

          <Divider/>

          <Box sx={{py: 3, display: 'flex', flexDirection: 'column', gap: 2}}>
            <Box sx={{display: 'flex', gap: 1, width: '100%', alignItems: 'center'}}>
              <ListIcon sx={{fontSize: '35px'}}/>

              <TextField id="list_name" label="Name" variant="outlined" size={'small'}
                         defaultValue={listInfo.name}
                         onChange={(e) => handleListInfoChange('name', e.target.value)}/>

              <TextField id="list_description" label="Description" variant="outlined" size={'small'}
                         sx={{flexGrow: 1}}
                         defaultValue={listInfo.description}
                         onChange={(e) => handleListInfoChange('description', e.target.value)}/>
            </Box>


            <Box sx={{display: 'flex', gap: 1, width: '100%', alignItems: 'center'}}>
              <SettingsIcon sx={{fontSize: '35px'}}/>

              <Tooltip title={'Creating a group will create a preset in the accounts export tool.'}>
                <TextField id="list_group" label="Group" variant="outlined" size={'small'}
                           defaultValue={listInfo.group}
                           onChange={(e) => handleListInfoChange('group', e.target.value)}/>
              </Tooltip>

              <Tooltip title={'Indicate whether the accounts in this list can be synced to 1Ticket.'}>
                <FormControlLabel
                  control={
                    <Switch checked={listInfo.sync === undefined ? true : listInfo.sync}
                            onChange={(e: any) => {
                              handleListInfoChange('sync', e.target.checked);
                            }}
                            name="manager_synced"
                    />
                  }
                  label="Can Be Synced"
                />
              </Tooltip>
            </Box>
          </Box>

          <Divider/>

          <Box sx={{pt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            {edit && DeleteList({list: listInfo})}

            <Box sx={{display: 'flex', alignItems: 'center', gap: 1, ml: 'auto'}}>
              <Button variant="text" sx={{color: getSelectedTheme().accent}}
                      onClick={handleClose}>Cancel</Button>
              <Button variant="contained"
                      sx={{backgroundColor: getSelectedTheme().accent, color: 'white'}}
                      disabled={isLoading || !listInfo.name}
                      onClick={handleSubmit}>
                {isLoading ? <Box sx={{pt: 0.1}}><CircularProgress size={20}/></Box>
                  : edit ? 'Save' : 'Add'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const DeleteList = (props: { list: ListT }) => {
  const [open, setOpen] = React.useState(false);
  const {handleListDelete} = useContext(ListsContext);
  const {enqueueSnackbar} = useSnackbar();
  const axiosInstance = useConfiguredAxios();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    axiosInstance.delete(`/api/lists/${props.list?.id}/`)
      .then(() => {
        handleListDelete(props.list.id);
        enqueueSnackbar('List deleted successfully!', {variant: 'success'});
        handleClose();
      })
      .catch(() => {
        enqueueSnackbar('Failed to delete list.', {variant: 'error'});
      });
  };

  return (
    <>
      <Button variant="text" sx={{color: getSelectedTheme().error}}
              onClick={handleOpen}>Delete</Button>
      <Dialog open={open} onClose={handleClose} maxWidth={'xs'} fullWidth>
        <DialogTitle>
          Delete this list?
          <Divider/>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The list "{props.list?.name}" will be deleted permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={'text'} onClick={handleClose}>Cancel</Button>
          <Button variant={'contained'} sx={{backgroundColor: getSelectedTheme().error}} color={'error'}
                  onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default List;
