// ----- Modules ----- //
import React, { useEffect } from "react";

// ----- MUI ----- //
import { Autocomplete, CircularProgress, debounce, TextField } from "@mui/material";

// ----- Utils ----- //
import { ListT } from "../../Utils/Types";
import { useConfiguredAxios } from "../../Utils/AxiosInstance";

const SubnetsSelect = (props: {
  onChange: any,
  disabled?: boolean,
}) => {
  const axiosInstance = useConfiguredAxios();

  const {onChange, disabled} = props;

  const [subnetsInput, setSubnetsInput] = React.useState<string>('');
  const [groups, setGroups] = React.useState<ListT[]>([]);
  const [loadGroups, setLoadGroups] = React.useState(false);

  // ----- Functions ----- //
  const fetchGroups = async (input: string) => {
    if (!input) return setGroups([]);
    setLoadGroups(true);
    const result = await axiosInstance.get(`/api/proxies/groups?subnet=${input}`);
    setGroups(result.data);
    setLoadGroups(false);
  };

  useEffect(() => {
    const debouncedFetch = debounce(fetchGroups, 250);
    debouncedFetch(subnetsInput);
    return () => debouncedFetch.clear();
  }, [subnetsInput]);

  return (
    <Autocomplete
      id="proxy-groups"
      size={'small'}

      disabled={disabled}
      options={groups || []}
      multiple
      autoHighlight
      fullWidth

      getOptionLabel={(option: any) => option.subnet}
      renderInput={(params) =>
        <TextField {...params} label="Subnets" variant="outlined" size={'small'}
                   InputProps={{
                     ...params.InputProps,
                     endAdornment: (
                       <React.Fragment>
                         {loadGroups ? <CircularProgress color="inherit" size={20}/> : null}
                         {params.InputProps.endAdornment}
                       </React.Fragment>
                     ),
                   }}
        />}

      onChange={onChange}
      onInputChange={(e, value) => {
        setSubnetsInput(value);
      }}

      noOptionsText={groups.length === 0 && subnetsInput ? 'No subnets found' : 'Type to search'}
      filterOptions={(x) => x}
      loading={loadGroups}
    />
  );
};

export default SubnetsSelect;
