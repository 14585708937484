// ----- Module ----- //
import React, { JSX } from "react";

// ----- MUI ----- //
import { Box, ButtonBase, IconButton, Typography } from "@mui/material";
import ColumnsIcon from '@mui/icons-material/ViewWeekOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import { Pages } from "../Utils/Types";
import { getSelectedTheme } from "../Utils/Colors";
import { FONTS } from "../index";

/**
 * Action Buttons Component
 */
const ActionBtns = (props: {
  tab: Pages,
  SelectColumns: () => void,
  exportElements: () => void,
  CreateElement: JSX.Element,
  BulkActionsElement?: JSX.Element
}) => {
  const {SelectColumns, exportElements, CreateElement, BulkActionsElement} = props;

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '20px'
    }}>

      {/* Show/Hide Columns */}
      <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
        <ButtonBase
          onClick={SelectColumns}
          sx={{display: 'flex', alignItems: 'center', height: '100%', pr: 0.5}}
        >
          <IconButton aria-label={"Download" + (props.tab === Pages.accounts ? ' accounts' : ' proxies')}
                      component="span" sx={{p: 0.5}}>
            <ColumnsIcon sx={{color: getSelectedTheme().accent, fontSize: '40px'}}/>
          </IconButton>

          <Typography sx={{whiteSpace: 'nowrap', mt: 1.4}} fontFamily={FONTS}
                      fontSize={20}>
            Columns To Show
          </Typography>
        </ButtonBase>
      </Box>

      <Box sx={{bgcolor: getSelectedTheme().lightLine, height: '3px', width: '75px'}}/>

      {/* Export Filtered Proxies */}
      <Box sx={{display: 'flex', alignItems: 'center', height: '100%', position: 'relative', left: '-7px'}}>
        <ButtonBase
          onClick={exportElements}
          sx={{display: 'flex', alignItems: 'center', height: '100%', pr: 0.5}}
        >
          <IconButton aria-label={"Download" + (props.tab === Pages.accounts ? ' accounts' : ' proxies')}
                      component="span" sx={{p: 0.5}}>
            <FileDownloadIcon sx={{color: getSelectedTheme().accent, fontSize: '40px'}}/>
          </IconButton>

          <Typography sx={{whiteSpace: 'nowrap', mt: 1.4}} fontFamily={FONTS}
                      fontSize={20}>
            Filtered {props.tab === Pages.accounts ? 'Accounts' :
            props.tab === Pages.lists ? 'Lists' :
              props.tab === Pages.proxies ? 'Proxies' :
                'Domains'}
          </Typography>
        </ButtonBase>
      </Box>

      {/* Bulk Actions */}
      {BulkActionsElement && (
        <>
          <Box sx={{bgcolor: getSelectedTheme().lightLine, height: '3px', width: '75px'}}/>
          {BulkActionsElement}
        </>
      )}

      <Box sx={{bgcolor: getSelectedTheme().lightLine, height: '3px', width: '100%'}}/>

      {/* Create New Account */}
      <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
        {CreateElement}

        <Typography sx={{whiteSpace: 'nowrap', mt: 1.4, mr: 1.5}}
                    fontFamily={FONTS} fontSize={20}>
          Create New {props.tab === Pages.accounts ? 'Account' :
          props.tab === Pages.lists ? 'List' :
            props.tab === Pages.proxies ? 'Proxy' :
              'Domain'}
        </Typography>
      </Box>
    </Box>
  );
};

export default ActionBtns;
