export type DispatchFunction = (action: { type: string; payload: any }) => void;

class WebSocketService {
  private socket: WebSocket;
  private messageHandlers: DispatchFunction[] = [];

  constructor() {
    const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:5050';
    this.socket = new WebSocket(websocketUrl);
  }

  init(): void {
    this.socket.onmessage = (event: MessageEvent) => {
      const message = JSON.parse(event.data);
      this.messageHandlers.forEach(handler => handler(message));
    };

    this.socket.onopen = () => {
      console.log('WebSocket Connected');
    };

    this.socket.onerror = (error: Event) => {
      console.error('WebSocket Error:', error);
    };

    this.socket.onclose = () => {
      console.log('WebSocket Disconnected');
    };
  }

  registerHandler(handler: DispatchFunction): void {
    this.messageHandlers.push(handler);
  }

  unregisterHandler(handler: DispatchFunction): void {
    this.messageHandlers = this.messageHandlers.filter(h => h !== handler);
  }

  sendMessage(message: any): void {
    this.socket.send(JSON.stringify(message));
  }

  // Optionally, you can add a method to cleanly disconnect the WebSocket
  disconnect(): void {
    if (this.socket) this.socket.close();
  }
}

export default new WebSocketService();
