// ----- Modules ----- //
import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useConfiguredAxios } from "./Utils/AxiosInstance";
import LogRocket from "logrocket";

// ----- Pages ----- //
import Main from "./pages/Main";
import Error from "./pages/Error";

// ----- MUI ----- //
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// ----- Components ----- //
import RequestsStatus from "./components/modal/RequestsStatus";
import News from "./components/modal/News";

// ----- Utils ----- //
import DefaultTheme from "./Utils/Theme/Theme";
import { Pages } from "./Utils/Types";
import ProvidersWrapper from "./contexts/ProvidersWrapper";

/**
 * Main App component for the application.
 */
function App() {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(false);

  // ----- Effects ----- //
  useEffect(() => {
    axiosInstance.get('/api/auth/status')
      .then((response) => {
        const data = response.data as { isAuthenticated: boolean, user: { id: string, name: string, email: string } };
        if (!data) {
          setError(true);
          return;
        }
        if (!data.isAuthenticated) window.location.href = process.env.NODE_ENV === 'production' ? 'https://messi.plessinc.com/api/auth/google' : 'http://localhost:5050/api/auth/google';
        setIsAuthenticated(data.isAuthenticated);

        if (data.isAuthenticated) setupLogRocket(data.user);
      });
  }, []);

  const setupLogRocket = (user: { id: string, name: string, email: string }) => {
    LogRocket.identify(user.id, {
      name: user.name,
      email: user.email,
    });
  };


  // ----- Render ----- //
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={DefaultTheme()}>
        <CssBaseline/>
        <Router>
          {isAuthenticated ? (
            <>
              <News/>
              <ProvidersWrapper>
                <Routes>
                  <Route
                    path="/accounts"
                    element={<Main page={Pages.accounts}/>}
                  />
                  <Route
                    path="/lists"
                    element={<Main page={Pages.lists}/>}
                  />
                  <Route
                    path="/proxies"
                    element={<Main page={Pages.proxies}/>}
                  />
                  <Route
                    path="/domains"
                    element={<Main page={Pages.domains}/>}
                  />
                  <Route
                    path="/*"
                    element={<Main page={Pages.accounts}/>}
                  />
                </Routes>
                <RequestsStatus/>
              </ProvidersWrapper>
            </>
          ) : (
            <Error error={error}/>
          )}
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
