// ----- Module ----- //
import React, { useCallback, useContext } from "react";
import moment from "moment";
import { useConfiguredAxios } from "../../Utils/AxiosInstance";

// ----- MUI ----- //
// Components
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
// Icons
import CreditCardIcon from "@mui/icons-material/CreditCard";

// ----- Utils ----- //
import { CreditCardsContext } from "../../contexts/CreditCardsProvider";
import { AccountT, CcT, CreditCardT } from "../../Utils/Types";
import { ModalStyle } from "../../Utils/Theme/Theme";
import { getSelectedTheme } from "../../Utils/Colors";
import { FONTS } from "../../index";
import { enqueueSnackbar } from "notistack";
import { AccountsContext } from "../../contexts/AccountsProvider";
import Title from "./components/Title";

/**
 * Credit Card Modal
 * - Used to create or edit a credit card associated with an account.
 * @param props {account_id: number, cc_id: number}
 * - account_id: The account id on which the credit card is associated.
 * - cc_id: The credit card id. Can be null if creating a new credit card.
 */
const CreditCard = (props: { account_id: number, cc_info: CreditCardT }) => {
  const axiosInstance = useConfiguredAxios();
  // ----- Context ----- //
  const {types} = useContext(CreditCardsContext);
  const {data: accounts, handleEdit} = useContext(AccountsContext);

  // ----- Props ----- //
  const {account_id, cc_info} = props;

  // ----- States ----- //
  const [ccInfo, setCCInfo] = React.useState(cc_info ? cc_info : {} as CreditCardT);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  // ----- Functions ----- //
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  /**
   * Handle the change of the credit card info.
   * @param key{string} - The key of the credit card info.
   * @param value{string | number | {}} - The value of the credit card info.
   */
  const handleCCInfoChange = (key: string, value: string | number | {}) => {
    if (!ccInfo) return;
    setCCInfo({...ccInfo, [key]: value});
  };

  /**
   * Send a request to the server to update the credit card info.
   */
  const handleSubmit = useCallback(() => {
    if (!ccInfo) return;
    setLoading(true);
    axiosInstance.post(`/api/creditcards/update/${account_id}`, ccInfo)
      .then(() => {
          ccInfo.type = types.find((t: CcT) => t.id === ccInfo.type_id) as CcT;
          setLoading(false);
          ccInfo.updated_at = moment().format('YYYY-MM-DD HH:mm:ss');

          const account = accounts.find((a: AccountT) => a.id === account_id) as AccountT;
          account.cc_id = ccInfo.id;
          account.cc_info = ccInfo;
          handleEdit(account);

          handleClose();
          enqueueSnackbar('Credit Card updated successfully!', {variant: 'success'});
        }
      ).catch((error) => {
      enqueueSnackbar('Failed to update credit card.', {variant: 'error'});
      console.log(error);
    });
  }, [account_id, ccInfo]);

  // Check if the credit card is expired.
  const expDate = ccInfo ? moment(ccInfo.exp, 'MM/YY') : null;

  // ----- Render ----- //
  return (
    <>
      <Tooltip title={!ccInfo?.last_4 || !ccInfo?.cvv || !ccInfo?.exp ? 'Incomplete'
        : expDate && expDate.isBefore(moment()) ? 'Expired' : ''} placement={'top'} disableInteractive>
        <IconButton aria-label="Show Credit Card Info" component="span"
                    sx={{p: 0.25, position: 'relative', left: '-7px'}}
                    onClick={handleOpen}>
          <CreditCardIcon sx={{
            color: !ccInfo?.last_4 || !ccInfo?.cvv || !ccInfo?.exp ? getSelectedTheme().warning : expDate && expDate.isBefore(moment()) ? getSelectedTheme().error : getSelectedTheme().accent,
            fontSize: '30px'
          }}/>
        </IconButton>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        style={{backdropFilter: "blur(2px)"}}
      >
        <Box sx={ModalStyle}>
          <Title title={'Edit credit card'} icon={<CreditCardIcon sx={{fontSize: '35px'}}/>}/>

          <Divider/>

          <Box sx={{py: 3}}>
            <Box sx={{display: 'flex', gap: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
              <FormControl sx={{minWidth: 125}}>
                <InputLabel id="cc-type-label" size={'small'}
                >Type</InputLabel>
                <Select
                  labelId="cc-type-label"
                  id="cc-type"
                  value={ccInfo.type_id}
                  label="Type"
                  size={'small'}
                  onChange={(e) => handleCCInfoChange('type_id', e.target.value)}
                >
                  {types.map((type: CcT) => (
                    <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField id="cc_last_4" label="Last 4" variant="outlined" size={'small'}
                         defaultValue={ccInfo.last_4}
                         placeholder={'****'}
                         error={ccInfo.last_4?.length !== 4}
                         onChange={(e) => handleCCInfoChange('last_4', e.target.value)}/>

              <TextField id="cc_cvv" label="CVV" variant="outlined" size={'small'}
                         defaultValue={ccInfo.cvv}
                         placeholder={'****'}
                         error={ccInfo.cvv?.length < 3}
                         onChange={(e) => handleCCInfoChange('cvv', e.target.value)}/>

              <DatePicker
                slotProps={{
                  textField: {
                    size: 'small',
                    style: {minWidth: '105px'},
                    error: moment(ccInfo.exp, 'MM/YY').isBefore(moment()) || !ccInfo.exp
                  }
                }}
                label={"Exp"}
                value={expDate}
                views={['month', 'year']}
                format={'MM/YY'}
                onChange={(e) => handleCCInfoChange('exp', e ? moment(e).format('MM/YY') : '')}
              />

              <FormControl sx={{minWidth: 100}}>
                <InputLabel id="cc-currency-label" size={'small'}
                >Currency</InputLabel>
                <Select
                  labelId="cc-currency-label"
                  id="cc-currency"
                  value={ccInfo.currency}
                  label="Currency"
                  size={'small'}
                  error={!ccInfo.currency}
                  onChange={(e) => handleCCInfoChange('currency', e.target.value)}
                >
                  <MenuItem value={'USD'}>USD</MenuItem>
                  <MenuItem value={'CAD'}>CAD</MenuItem>
                </Select>
              </FormControl>

              <Tooltip title={'Only for AMEX'} placement={'top'} disableInteractive>
                <TextField id="cc_safekey" label="Safekey" variant="outlined" size={'small'}
                           disabled={ccInfo.type_id !== 1} error={ccInfo.type_id === 1 && !ccInfo.safekey}
                           defaultValue={ccInfo.safekey}
                           onChange={(e) => handleCCInfoChange('safekey', e.target.value)}/>
              </Tooltip>
            </Box>
          </Box>

          <Divider/>

          <Box
            sx={{display: 'flex', width: '100%', alignItems: 'center', pt: 2, gap: 1}}>

            {ccInfo?.id &&
              <Typography color={getSelectedTheme().darkText} lineHeight={1} fontFamily={FONTS}>
                Created: {moment(ccInfo.created_at).format('MM/DD/YYYY HH:mm:ss')}
                <br/>
                {ccInfo.created_at !== ccInfo.updated_at &&
                  <span>Updated: {moment(ccInfo.updated_at).format('MM/DD/YYYY HH:mm:ss')}</span>}

              </Typography>
            }

            <Box sx={{ml: 'auto'}}>
              <Button variant="text" sx={{color: getSelectedTheme().accent}}
                      onClick={handleClose}>Cancel</Button>
              <Button variant="contained"
                      sx={{backgroundColor: getSelectedTheme().accent, color: 'white'}}
                      disabled={
                        !ccInfo ||
                        !ccInfo.type_id ||
                        (ccInfo.type_id === 1 && !ccInfo.safekey) ||
                        !ccInfo.last_4 ||
                        ccInfo.last_4.length !== 4 ||
                        !ccInfo.cvv ||
                        !ccInfo.exp ||
                        !ccInfo.currency ||
                        moment(ccInfo.exp, 'MM/YY').isBefore(moment()) ||
                        loading
                      }
                      onClick={handleSubmit}>
                {loading ? <Box sx={{pt: 0.1}}><CircularProgress size={20}/></Box>
                  : 'Save'}
              </Button>
            </Box>
          </Box>

        </Box>
      </Modal>
    </>
  );
};

export default CreditCard;
