// ----- Module ----- //
import React from "react";

// ----- MUI ----- //
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";

// ----- Utils ----- //
import { getSelectedTheme } from "../../../Utils/Colors";
import { FONTS } from "../../../index";

const Title = (props: { title: string, subtitle?: string, icon: any, close?: () => void }) => {
  const {title, icon, close} = props;
  return (
    <Box sx={{display: "flex", alignItems: "center", mt: 2, mb: 1}}>
      {icon}
      <Typography sx={{ml: 1, pt: 1}} variant={'h4'}
                  fontFamily={FONTS}>
        {title.toUpperCase()}
      </Typography>

      {props.subtitle && (
        <Typography fontFamily={FONTS} fontSize={16}
                    sx={{color: getSelectedTheme().lightText, position: 'relative', bottom: '-9px', pl: 1}}>
          {props.subtitle}
        </Typography>
      )}

      {close && (
        <IconButton size={'large'} sx={{color: getSelectedTheme().lightText, ml: 'auto', p: 1}}
                    onClick={close}>
          <CloseIcon/>
        </IconButton>
      )}
    </Box>
  );
};

export default Title;
