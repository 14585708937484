// ----- Modules ----- //
import React, { useEffect } from "react";

// ----- MUI ----- //
import { Autocomplete, CircularProgress, debounce, TextField } from "@mui/material";

// ----- Utils ----- //
import { ListT } from "../../Utils/Types";
import { useConfiguredAxios } from "../../Utils/AxiosInstance";

const DomainsSelect = (props: {
  onChange: any,
  disabled?: boolean,
}) => {
  const axiosInstance = useConfiguredAxios();

  const {onChange, disabled} = props;

  const [domainsInput, setDomainsInput] = React.useState<string>('');
  const [domains, setDomains] = React.useState<ListT[]>([]);
  const [loadDomains, setLoadDomains] = React.useState(false);

  // ----- Functions ----- //
  const fetchGroups = async (input: string) => {
    if (!input) return setDomains([]);
    setLoadDomains(true);
    const result = await axiosInstance.get(`/api/domains?domain=${input}`);
    setDomains(result.data);
    setLoadDomains(false);
  };

  useEffect(() => {
    const debouncedFetch = debounce(fetchGroups, 250);
    debouncedFetch(domainsInput);
    return () => debouncedFetch.clear();
  }, [domainsInput]);

  return (
    <Autocomplete
      id="account-domains"
      size={'small'}

      disabled={disabled}
      options={domains || []}
      multiple
      autoHighlight
      fullWidth

      getOptionLabel={(option: any) => option.domain}
      renderInput={(params) =>
        <TextField {...params} label="Domains" variant="outlined" size={'small'}
                   InputProps={{
                     ...params.InputProps,
                     endAdornment: (
                       <React.Fragment>
                         {loadDomains ? <CircularProgress color="inherit" size={20}/> : null}
                         {params.InputProps.endAdornment}
                       </React.Fragment>
                     ),
                   }}
        />}

      onChange={onChange}
      onInputChange={(e, value) => {
        setDomainsInput(value);
      }}

      noOptionsText={domains.length === 0 && domainsInput ? 'No domains found' : 'Type to search'}
      filterOptions={(x) => x}
      loading={loadDomains}
    />
  );
};

export default DomainsSelect;
