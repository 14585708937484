// ----- Module ----- //
import React, { ReactNode, useEffect, useState } from "react";
import { useConfiguredAxios } from "../Utils/AxiosInstance";

// ----- Utils ----- //
import { AccountT, IContext, QueryStateT } from "../Utils/Types";
import WebSocketManager from "../services/WebSocketManager";

interface AccountsContext extends IContext {
  handleEdit: (account: AccountT) => void;
  handleCreate: (account: AccountT) => void;
  handleDelete: (id: number) => void;
}

interface AccountsProviderProps {children: ReactNode;}

const AccountsContext =
  React.createContext<AccountsContext>(
    {
      data: [],
      count: 0,
      fetchData: async () => [],
      isLoading: true,

      handleEdit: () => {},
      handleCreate: () => {},
      handleDelete: () => {},
    });

/**
 * This component is a wrapper for the entire application.
 * It provides the context for the accounts.
 * @param children - The children of the component.
 */
const AccountsProvider = ({children}: AccountsProviderProps) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState([] as AccountT[]);
  const [count, setCount] = useState(0);

  const [pageInfo, setPageInfo] = useState({page: 1, pageSize: 100});

  const handleAccountInfoChange = (method: string, data: AccountT) => {
    setAccounts((currentAccounts) => {
      switch (method) {
        case 'POST':
          return [data, ...currentAccounts];
        case 'PUT':
          return currentAccounts.map((a) => {
            if (a.id === data.id) return {...a, ...data};
            return a;
          });
        case 'DELETE':
          return currentAccounts.filter((a) => a.id !== data.id);
        default:
          return currentAccounts;
      }
    });
  };

  useEffect(() => {
    const handleWebSocketMessage = (message: any) => {
      if (message.type === 'ACCOUNTS')
        handleAccountInfoChange(message.method, message.data.account);
    };

    // Create a new instance of WebSocketManager
    const webSocketManager = new WebSocketManager(handleWebSocketMessage);

    // Cleanup function
    return () => {
      webSocketManager.disconnect(); // Disconnect WebSocket on unmount
    };
  }, []);

  const fetchAccounts = (queryState?: QueryStateT) => {
    let {filters, sort, page, pageSize} = queryState || {};
    setIsLoading(true);

    if (!page && page != 0) page = pageInfo.page;
    if (!pageSize && pageSize != 0) pageSize = pageInfo.pageSize;

    setPageInfo({page, pageSize});

    const filtersJson = filters?.length ? `&filters=${encodeURIComponent(JSON.stringify(filters))}` : '';
    const sortJson = sort?.length ? `&sort=${encodeURIComponent(JSON.stringify(sort))}` : '';
    axiosInstance.get(`/api/accounts?page=${page}&pageSize=${pageSize}` + filtersJson + sortJson)
      .then((response) => {
        const data = response.data;
        setAccounts(data.accounts);
        setCount(data.total);

        setIsLoading(false);
      });
  };

  // ----- Functions ----- //
  /**
   * This function updates the accounts state. It is passed to the AccountsContext.
   */
  const handleEdit = (account: AccountT) => {
    const newAccounts = accounts.map((a: AccountT) => {
      if (a.id === account.id) return account;
      return a;
    });
    setAccounts(newAccounts);
  };

  /**
   * This function adds a new account to the accounts state. It is passed to the AccountsContext.
   */
  const handleCreate = (account: AccountT) => {
    const newAccounts = [account, ...accounts];
    setAccounts(newAccounts);
  };

  /**
   * This function removes an account from the accounts state. It is passed to the AccountsContext.
   */
  const handleDelete = (id: number) => {
    const newAccounts = accounts.filter((a: AccountT) => a.id !== id);
    setAccounts(newAccounts);
  };

  // ----- Render ----- //
  return (
    <AccountsContext.Provider
      value={{
        data: accounts, count, fetchData: fetchAccounts, isLoading, handleEdit, handleCreate, handleDelete
      }}>
      {children}
    </AccountsContext.Provider>
  );
};

export { AccountsContext, AccountsProvider };
