// ----- Modules ----- //
import React, { useCallback, useEffect, useState } from "react";

// ----- MUI ----- //
import { Box, LinearProgress, Paper } from "@mui/material";

// ----- Utils ----- //
import { DarkDataGridPremium } from "../../Utils/Theme/Theme";
import { IContext } from "../../Utils/Types";


interface TabProps<T extends IContext> {
  columns: any,
  toolbar: React.JSXElementConstructor<any>,
  context: React.Context<T>,
}

/**
 * Default list component
 *   - columns: columns for the list
 *   - data: data for the list
 *   - toolbar: custom toolbar that appears above the list
 *   - loading: whether the list is loading
 * @param props - props for the component
 */
function Tab<T extends IContext>(props: TabProps<T>) {
  const {columns, toolbar, context} = props;
  const contextValue = React.useContext(context);
  const {fetchData, data, count, isLoading} = contextValue;

  // Create a state to manage the debounce timeout ID
  const [filtersDebounceTimeout, setFiltersDebounceTimeout]
    = useState<NodeJS.Timeout | null>(null);
  const [paginationDebounceTimeout, setPaginationDebounceTimeout]
    = useState<NodeJS.Timeout | null>(null);

  const [filterModel, setFilterModel] = useState<any>({items: []});
  const [sortModel, setSortModel] = useState<any>([]);

  const mapOperatorNames = (operator: string) => {
    const operatorMapping = {
      contains: 'like',
      equals: 'eq',
      before: 'lt',
      after: 'gt',
      onOrBefore: 'lte',
      onOrAfter: 'gte',
      is: 'eq',
    } as any;
    return operatorMapping[operator] || operator;
  };

  const search = useCallback((page = 0, pageSize = 100) => {
    const filters = filterModel ? filterModel.items.map((item: any) => {
      return {
        field: item.field,
        operator: mapOperatorNames(item.operator),
        value: item.value,
      };
    }) : [];

    const sort = sortModel.length ? sortModel.map((item: any) => {
      return {
        field: item.field,
        order: item.sort,
      };
    }) : [];

    fetchData({filters, sort, page, pageSize});
  }, [filterModel, sortModel, fetchData]);

  useEffect(() => {
    search();
  }, [filterModel, sortModel]);

  const handleModelChange = (model: any) => {
    if (filtersDebounceTimeout) clearTimeout(filtersDebounceTimeout);

    console.log('model', model);

    const newTimeout = setTimeout(() => {
      if (!Array.isArray(model)) {
        console.log('filter model');
        setFilterModel(model);
      } else {
        console.log('sort model');
        setSortModel(model);
      }
    }, 500);

    setFiltersDebounceTimeout(newTimeout);
  };

  const handlePaginationModelChange = (params: any) => {
    if (paginationDebounceTimeout) clearTimeout(paginationDebounceTimeout);
    const newTimeout = setTimeout(() => {
      search(params.page, params.pageSize);
    }, 250);
    setPaginationDebounceTimeout(newTimeout);
  };

  // ----- Render ----- //
  return (
    <Box sx={{height: '95%', pt: 3}}>
      <Paper elevation={3}
             id={'content'}
             sx={{
               borderRadius: '7px',
               boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.25)',
               height: '100%',
               mx: 12,
               px: 1,
             }}>
        <Box sx={{
          mx: 'auto', px: 3,
          height: '100%',
          pb: 3,
        }}>
          <DarkDataGridPremium
            loading={isLoading || false}
            rows={data || []}
            columnHeaderHeight={50}
            columns={columns || []}
            slots={{
              toolbar: toolbar || null,
              [isLoading && data.length !== 0 ? "loadingOverlay" : ""]: LinearProgress,
            }}
            initialState={{
              density: "compact",
              columns: {
                columnVisibilityModel: {
                  id: false,
                  manager_status: false,
                }
              },
              sorting: {
                sortModel: [{field: 'created_at', sort: 'desc'}],
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? "row-even"
                : "row-odd"
            }

            disableRowGrouping
            disableAggregation

            headerFilters
            onFilterModelChange={handleModelChange}
            onSortModelChange={handleModelChange}
            filterMode="server"
            sortingMode="server"

            pagination
            onPaginationModelChange={handlePaginationModelChange}
            paginationMode="server"
            pageSizeOptions={[100]}

            rowCount={count}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export const handleColumnsSelector = () => {
  const columnButton = document.querySelector('#column-visibility button') as HTMLButtonElement;
  if (columnButton)
    columnButton.click();
};


export default Tab;
