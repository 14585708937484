// ----- Modules ----- //
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment/moment";
import { saveAs } from "file-saver";
import axios from "axios";
import JSZip from "jszip";

// ----- MUI ----- //
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// ----- Components ----- //
import Template from "../Template";

// ----- Utils ----- //
import { AccountT, ListT } from "../../../../Utils/Types";
import { ListsContext } from "../../../../contexts/ListsProvider";
import ListsSelect from "../../../inputs/ListsSelect";

const ExportAccounts = () => {
  // ----- Context ----- //
  const {getGroups} = useContext(ListsContext);
  const {enqueueSnackbar} = useSnackbar();

  // ----- States ----- //
  const [processing, setProcessing] = useState(false);
  const [listCode, setListCode] = useState('');
  const [format, setFormat] = useState('csv');
  const [listsGroups, setListsGroups] = useState([] as string[]);
  const [selectedGroup, setSelectedGroup] = useState('');

  // ----- Effects ----- //
  useEffect(() => {
    getGroups().then((groups) => {
      setListsGroups(groups);
    });
  }, [getGroups]);


  // ----- Functions ----- //
  const generateCSV = (filteredAccounts: AccountT[]): string => {
    const header = ['id', 'lists', 'name', 'email', 'phone', 'password', 'address', 'city', 'state', 'zip', 'cc_type', 'cc_last_4', 'cc_cvv', 'cc_exp', 'cc_currency', 'comment'];
    const rows = filteredAccounts.map((account) => [
      account.id,
      account.lists.map((list: ListT) => list.name).join(' - '),
      account.name,
      account.email,
      account.phone,
      account.password,
      account.address,
      account.city,
      account.state,
      account.zip,
      account.cc_info?.type?.name || "",
      account.cc_info?.last_4,
      account.cc_info?.cvv,
      account.cc_info?.exp ? moment(account.cc_info.exp).format('MM/YY') : "",
      account.cc_info?.currency,
      account.comment,
    ]);

    return [header, ...rows].map(row => row.join(',')).join('\n');
  };

  const generateJSON = (filteredAccounts: AccountT[]): string => {
    return JSON.stringify(filteredAccounts, null, 2);
  };

  /**
   * Download all accounts from a specific list to a specific format.
   * If no list is specified, download all accounts
   */
  const downloadList = useCallback(async () => {
    setProcessing(true);

    const data = await axios.get(`/api/accounts/export?group=${selectedGroup}&lists=${listCode}&format=${format}`)
      .then((response) => {
        return response.data;
      }) as { list: string, accounts: AccountT[] }[];

    const files = [] as { content: Blob, fileName: string, code: string }[];
    data.forEach((element) => {
      if (!element.accounts.length) {
        enqueueSnackbar(`No accounts were found for the list ${element.list}`, {variant: 'warning'});
        setProcessing(false);
        return;
      }

      let content: Blob;
      let fileExtension: string;

      switch (format) {
        case 'csv':
          content = new Blob([generateCSV(element.accounts)], {type: 'text/csv'});
          fileExtension = 'csv';
          break;
        case 'json':
          content = new Blob([generateJSON(element.accounts)], {type: 'application/json'});
          fileExtension = 'json';
          break;
        default:
          return;
      }

      const fileName = `${element.list ? element.list : 'all'}-accounts_${moment().format('YYYY-MM-DD')}.${fileExtension}`;
      files.push({content, fileName, code: element.list});
    });

    if (!files.length) return;

    if (files.length > 1) {
      const zip = new JSZip();
      files.forEach((file) => zip.file(file.fileName, file.content));
      zip.generateAsync({type: 'blob'}).then((content) => saveAs(content, `accounts_${moment().format('YYYY-MM-DD')}.zip`));
    } else
      saveAs(files[0].content, files[0].fileName);

    enqueueSnackbar(listCode ? `Accounts for list${files.length > 1 ? 's' : ''}
    ${files.length > 1 ? files.map((file) => file.code).join(', ') : files[0].code} downloaded successfully!` : 'All accounts downloaded successfully!', {variant: 'success'});

    setProcessing(false);
  }, [selectedGroup, listCode, format]);

  const input = (
    <ListsSelect
      default_lists={[]}
      onChange={
        (_: any, value: any) => {
          setListCode(value.map((list: ListT) => list.name).join(','));
        }}
      disabled={processing || !!selectedGroup}
    />
  );

  const presets = (<>
    <FormControl size={'small'} sx={{width: '100%'}}>
      <InputLabel id="export-presets-label">Presets</InputLabel>
      <Select
        id="export-presets"
        labelId={'export-presets-label'}
        label={'Format'}
        size="small"
        value={selectedGroup}
        onChange={(e) => {
          const value = e.target.value;
          setSelectedGroup(value);
        }}
      >
        <MenuItem value={''} sx={{color: 'text.disabled'}}>None</MenuItem>
        {listsGroups.map((group, index) => (
          <MenuItem key={index} value={group}>{group}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </>);

  const type = (
    <FormControl size={'small'} sx={{width: '100%'}}>
      <InputLabel id="export-type-label">Format</InputLabel>
      <Select
        id="export-type"
        labelId={'export-type-label'}
        label={'Format'}
        size="small"
        value={format}
        onChange={(e) =>
          setFormat(e.target.value as string)}
      >
        <MenuItem value={'csv'}>CSV</MenuItem>
        <MenuItem value={'json'}>JSON</MenuItem>
      </Select>
    </FormControl>
  );

  const button = (
    <Button
      sx={{width: '100%'}}
      variant="contained"
      onClick={() => downloadList()}
      disabled={processing || (!selectedGroup && !listCode)}
    >
      EXPORT
    </Button>
  );

  return (
    <Template slot_1={presets} slot_2={input} slot_3={type} slot_4={button}/>
  );
};


export default ExportAccounts;
