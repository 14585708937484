// ----- Modules ----- //
import moment from "moment";

// ----- MUI ----- //
import { Tooltip } from "@mui/material";

const CellDateTime = (props: { value: string }) => {
  const {value} = props;

  return (
    <>
      {value && (
        <Tooltip title={value ? moment(value).format("HH:mm:ss") : ""} placement={'right'}
                 arrow disableInteractive>
          <span>{value ? moment(value).format("YYYY-MM-DD") : ""}</span>
        </Tooltip>
      )}
    </>
  );
};

export default CellDateTime;
