// ----- Modules ----- //
import React, { ReactNode, useCallback, useState } from "react";

// ----- Utils ----- //
import { Pages } from "../Utils/Types";

interface NavContext {
  tab: number;
  handleTabChange: (tab: number) => void;
}

interface NavProviderProps {children: ReactNode;}

const NavContext = React.createContext<NavContext>({tab: 0, handleTabChange: () => {}});

/**
 * This component is a wrapper for the entire application.
 * It provides the context for the navigation bar.
 * @param children - The children of the component.
 */
const NavProvider = ({children}: NavProviderProps) => {
  // ----- States ----- //
  const [tab, setTab] = useState(0);

  const handleTabChange = useCallback((tab: number) => {
    setTab(tab);
    history.pushState(null, '', `/${Pages[tab]}`);
  }, []);

  // ----- Render ----- //
  return (
    <NavContext.Provider value={{tab: tab, handleTabChange: handleTabChange}}>
      {children}
    </NavContext.Provider>
  );
};

export { NavContext, NavProvider };
