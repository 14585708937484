import { Autocomplete, TextField, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { StatusT } from "../../Utils/Types";
import { StatusContext } from "../../contexts/StatusProvider";

const StatusSelect = (props: {
  default_status: StatusT | null,
  onChange: any,
  disabled?: boolean,
  system?: boolean
}) => {
  const {default_status, onChange, disabled, system} = props;

  const {status} = useContext(StatusContext);

  return (
    <Autocomplete
      id="account-status"
      options={
        system ?
          status :
          status.filter((s: StatusT) => !s.system_managed)
      }
      size={'small'}
      fullWidth
      autoHighlight
      disabled={disabled || (default_status?.system_managed && !system)}
      defaultValue={default_status}
      getOptionLabel={(option: any) => option.name}
      renderOption={(props, option) => (
        <Tooltip title={option.description} placement="left" arrow>
          <li {...props}>
                      <span style={{
                        height: '10px',
                        width: '10px',
                        backgroundColor: option.color,
                        borderRadius: '50%',
                        display: 'inline-block',
                        marginRight: '10px'
                      }}/>
            {option.name}
          </li>
        </Tooltip>
      )}
      renderInput={(params) =>
        default_status?.system_managed && !system ?
          <Tooltip title={'This status is managed by the system.'} placement="top" arrow>
            <TextField {...params} label="Status" variant="outlined" size={'small'}/>
          </Tooltip> :
          <TextField {...params} label="Status" variant="outlined" size={'small'}/>
      }
      onChange={onChange}
    />
  );
};

export default StatusSelect;
