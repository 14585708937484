import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useConfiguredAxios } from "../../../Utils/AxiosInstance";

const AccountsBalance = () => {
  const axiosInstance = useConfiguredAxios();

  const [accounts, setAccounts] = React.useState([] as { account: string, funds: number }[]);

  useEffect(() => {
    const fetch = async () => {
      const res = await axiosInstance.get('/api/creditcards/cxp/funds');
      setAccounts(res.data);
    };
    fetch().then();
  }, []);

  return <>
    <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%'}}>

      {accounts.map((fund, index) => (
        <Box
          key={index}
          title={fund.account}
          sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
        >
          <Typography
            variant={'h6'}
            color={'primary'}
            lineHeight={1}
          >
            {fund.account}
          </Typography>

          <Typography
            variant={'body2'}
            color={'textSecondary'}
            lineHeight={1}
          >
            $ {fund.funds.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
          </Typography>
        </Box>
      ))}
    </Box>
  </>;
};

export default AccountsBalance;
