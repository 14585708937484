// ----- Modules ----- //
import { useState } from "react";
import { enqueueSnackbar } from "notistack";

// ----- MUI ----- //
import { Box, Button } from "@mui/material";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckIcon from '@mui/icons-material/Check';
import ListIcon from "@mui/icons-material/FormatListBulleted";

// ----- Components ----- //
import WorkingSelect from "../../../inputs/WorkingSelect";
import ListsSelect from "../../../inputs/ListsSelect";
import CCTypeSelect from "../../../inputs/CCTypeSelect";

// ----- Utils ----- //
import { CreditCardT, ListT } from "../../../../Utils/Types";
import { useConfiguredAxios } from "../../../../Utils/AxiosInstance";

const CCTypes = () => {
  const axiosInstance = useConfiguredAxios();

  const [ccType, setCcType] = useState<CreditCardT | null>(null);
  const [list, setList] = useState<ListT | null>(null);
  const [working, setWorking] = useState<boolean>(false);

  const HandleUpdate = async () => {
    await axiosInstance.post('/api/creditcards/types/working', {
      cc_id: ccType?.id,
      list_id: list?.id,
      working: working,
    })
      .then((res) => {
        setCcType(res.data);
        enqueueSnackbar('Updated Successfully', {variant: 'success'});
      })
      .catch(() => {
        enqueueSnackbar('Failed to update', {variant: 'error'});
      });

    setWorking(false);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, width: '100%'}}>
      <Box sx={{display: 'flex', gap: 3, alignItems: 'center', width: '100%'}}>
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center', width: '100%'}}>
          <CreditCardIcon sx={{fontSize: '35px'}}/>

          <CCTypeSelect
            onChange={(value: CreditCardT) => {
              setCcType(value);
            }}/>
        </Box>
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center', width: '100%'}}>
          <ListIcon sx={{fontSize: '35px'}}/>

          <ListsSelect
            single
            default_lists={[]}
            onChange={(_: any, value: ListT) => {
              if (value) setList(value);
            }}/>
        </Box>
      </Box>

      <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <CheckIcon sx={{fontSize: '35px'}}/>

        <WorkingSelect
          onChange={(_: any, value: boolean) => {
            setWorking(value);
          }}
        />

        <Button
          disabled={!ccType || !list}
          onClick={HandleUpdate}
          variant={'contained'}
          color={'primary'}
        >
          Update
        </Button>

      </Box>
    </Box>
  );
};

export default CCTypes;
