export default [
  "The Karlos we all deserved. 🙌",
  "Karlos' era is finally over! 🎉",
  "Out with the old. In with the new! 🔄",
  "Evolution at its finest. 🚀",
  "No more waiting! ⏳",
  "Why walk when you can fly? 🕊️",
  "Redefining speed. 🏎️",
  "Blink and you'll miss it. 👀",
  "The pace of tomorrow, today. 🌌",
  "Who needs coffee with this speed? ☕️",
  "Goodbye lags. Hello future! 🌟",
  "Everyone's top pick. 🥇",
  "Breaking the limits. 💥",
  "Where speed meets magic. ✨",
  "Karlos? That's history. 📜",
  "Elevate your game. 🏔️",
  "Beyond imagination. 🌈",
  "Fasten your seatbelts! 🛫",
  "Not your grandfather's speed. 🌀",
  "Time to shine brighter! 💡",
  "The future is now. 🤖",
  "The best of the best. 🏆",
  "Karlos you say? That's so 2019. 📅",
  "Karlos who? Karlos what? 🤷",
  "Fresh looks. Fresh feels. 🍃",
  "Dive into a spectrum of colors! 🌈",
  "Now with more colors! 🎨",
];
