// ----- Module ----- //
import React, { ReactNode, useEffect, useState } from "react";
import { useConfiguredAxios } from "../Utils/AxiosInstance";

// ----- Utils ----- //
import { StatusT } from "../Utils/Types";

interface StatusContext {
  status: StatusT[];
  loading: boolean;
}

interface StatusProviderProps {children: ReactNode;}

const StatusContext =
  React.createContext<StatusContext>(
    {
      status: [],
      loading: true,
    });

/**
 * This component is a wrapper for the entire application.
 * It provides the context for the status.
 * @param children - The children of the component.
 */
const StatusProvider = ({children}: StatusProviderProps) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [status, setStatus] = useState([] as StatusT[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchStatus();
  }, []);

  const fetchStatus = () => {
    setLoading(true);
    axiosInstance.get('/api/status/')
      .then((response) => {
        setStatus(response.data);
        setLoading(false);
      });
  };


  // ----- Render ----- //
  return (
    <StatusContext.Provider
      value={{status, loading}}>
      {children}
    </StatusContext.Provider>
  );
};

export { StatusContext, StatusProvider };
