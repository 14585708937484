// ----- Modules ----- //
import React, { ReactNode, useState } from "react";
import { useConfiguredAxios } from "../Utils/AxiosInstance";

// ----- Utils ----- //
import { IContext, ListT, QueryStateT } from "../Utils/Types";

interface ListsContext extends IContext {
  getGroups: () => Promise<any[]>;
  handleListEdit: (list: ListT) => void;
  handleListCreate: (list: ListT) => void;
  handleListDelete: (id: number) => void;
}

interface ListsProviderProps {children: ReactNode;}

const ListsContext = React.createContext<ListsContext>({
  data: [],
  count: 0,
  fetchData: async () => [],
  isLoading: true,
  getGroups: async () => [],
  handleListEdit: () => {},
  handleListCreate: () => {},
  handleListDelete: () => {},
});

/**
 * This component is a wrapper for the entire application.
 * It provides the context for the lists.
 * @param children - The children of the component.
 */
const ListsProvider = ({children}: ListsProviderProps) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [isLoading, setIsLoading] = useState(true);
  const [lists, setLists] = useState<ListT[]>([]);
  const [count, setCount] = useState(0);

  const fetchLists = async (queryStateT?: QueryStateT) => {
    const {filters, sort, page, pageSize} = queryStateT || {};

    setIsLoading(true);
    const filtersJson = filters?.length ? `&filters=${encodeURIComponent(JSON.stringify(filters))}` : '';
    const sortJson = sort?.length ? `&sort=${encodeURIComponent(JSON.stringify(sort))}` : '';
    return await axiosInstance.get(`/api/lists?page=${page}&pageSize=${pageSize}` + filtersJson + sortJson)
      .then((response) => {
        const data = response.data;
        setLists(data.lists);
        setCount(data.total);

        setIsLoading(false);
        return data;
      });
  };

  const handleEdit = (list: ListT) => {
    const newLists = lists.map((l: ListT) => {
      if (l.id === list.id) return list;
      return l;
    });
    setLists(newLists);
  };

  const handleCreate = (list: ListT) => {
    const newLists = [...lists, list];
    setLists(newLists);
  };

  const handleDelete = (id: number) => {
    const newLists = lists.filter((list: ListT) => list.id !== id);
    setLists(newLists);
  };

  const getGroups = async () => {
    return await axiosInstance.get(`/api/lists/groups`)
      .then((response) => {
        return response.data;
      });
  };

  // ----- Render ----- //
  return (
    <ListsContext.Provider value={{
      data: lists,
      count,
      fetchData: fetchLists,
      isLoading,

      getGroups,
      handleListEdit: handleEdit,
      handleListCreate: handleCreate,
      handleListDelete: handleDelete,
    }}>
      {children}
    </ListsContext.Provider>
  );
};

export { ListsContext, ListsProvider };
