// ----- Modules ----- //
import React, { useCallback } from "react";
import { useSnackbar } from "notistack";

// ----- MUI ----- //
import { Box, Button, ButtonGroup, CircularProgress, Tooltip, Typography } from "@mui/material";
// Icons
import TemplateIcon from '@mui/icons-material/DescriptionOutlined';
import UploadIcon from '@mui/icons-material/DriveFolderUpload';

// ----- Components ----- //
import Template from "../Template";

// ----- Utils ----- //
import { useConfiguredAxios } from "../../../../Utils/AxiosInstance";
import { getSelectedTheme } from "../../../../Utils/Colors";
import { FONTS } from "../../../../index";

enum COLUMNS {
  'proxy_id',
  'new_ip',
  'new_port',
  'new_user',
  'new_pwd',
  'usage',
  'provider_id',
}

const ImportProxies = () => {
  const axiosInstance = useConfiguredAxios();

  // ----- Context ----- //
  const {enqueueSnackbar} = useSnackbar();

  const [file, setFile] = React.useState<File | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleUpload = useCallback(() => {
    if (!file) {
      enqueueSnackbar('No file selected', {variant: 'error'});
      return;
    }
    setLoading(true);
    formatFile(file);
  }, [file]);

  const formatFile = (file: File) => {
    const reader = new FileReader();

    reader.readAsText(file);

    reader.onload = async function () {
      let lines = reader.result?.toString().split('\n');

      if (!lines) {
        enqueueSnackbar('Invalid file', {variant: 'error'});
        return;
      }

      lines?.shift();

      lines = lines.filter((line) => {
        const proxy = line.split(',') as string[];
        return proxy[COLUMNS.new_ip] !== '';
      });

      const proxies = [] as any[];

      for (const line of lines) {
        const proxy = line.split(',') as string[];

        proxy[proxy.length - 1] = proxy[proxy.length - 1].replace('\r', '');

        proxies.push({
          proxy_id: proxy[COLUMNS.proxy_id] === '' ? null : proxy[COLUMNS.proxy_id],
          new_ip: proxy[COLUMNS.new_ip] === '' ? null : proxy[COLUMNS.new_ip],
          new_port: proxy[COLUMNS.new_port] === '' ? null : proxy[COLUMNS.new_port],
          new_user: proxy[COLUMNS.new_user] === '' ? null : proxy[COLUMNS.new_user],
          new_pwd: proxy[COLUMNS.new_pwd] === '' ? null : proxy[COLUMNS.new_pwd],
          new_usage: proxy[COLUMNS.usage] === '' ? null : proxy[COLUMNS.usage],
          new_provider_id: proxy[COLUMNS.provider_id] === '' ? null : proxy[COLUMNS.provider_id],
        });
      }

      enqueueSnackbar(`Uploading ${proxies.length} proxies...`, {variant: 'info'});

      console.log(proxies);
      await uploadProxies(proxies);

      setLoading(false);
      setFile(null);
    };
  };

  const uploadProxies = async (proxies: any[]) => {
    try {
      await axiosInstance.post('/api/proxies/import', proxies)
        .then((res) => {
          if (!res.data) return;
          enqueueSnackbar(`Successfully uploaded ${proxies.length} proxies!`, {variant: 'success'});
        });
    } catch (e) {
      enqueueSnackbar('Failed to upload proxies', {variant: 'error'});
      return;
    }
  };

  const input = (
    <Box sx={{position: 'relative'}}>
      <Typography
        sx={{position: 'absolute', color: getSelectedTheme().lightText, bottom: -20, whiteSpace: 'nowrap'}}
        fontFamily={FONTS} fontSize={14}>
        {file ? file.name : 'No file selected'}
      </Typography>

      <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{width: '100%'}}>
        <Tooltip title={'Open Template'} placement={'top'} arrow>
          <Button
            href={'https://docs.google.com/spreadsheets/d/1DPLcVreKeBNtcCEiBU2nLZhIH__BzYZThSs3o5IiR7M/edit'}
            target={'_blank'} sx={{borderTopRightRadius: 0, borderBottomRightRadius: 0, width: 20}}
            fullWidth>
            <TemplateIcon/>
          </Button>
        </Tooltip>
        <Tooltip title={'.CSV Only'} placement={'top'} arrow>
          <Button component="label" sx={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} endIcon={<UploadIcon/>}
                  fullWidth>
            <Typography sx={{pt: 0.5}} fontFamily={FONTS} fontSize={20}>
              Upload
            </Typography>
            <input type="file" accept=".csv" hidden onChange={(e) => setFile(e.target.files?.[0] ?? null)}/>
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );

  const info = (<></>);
  const type = (<></>);

  const button = (
    loading ? <Box sx={{px: 0.97}}><CircularProgress size={25}/></Box> :
      <Tooltip title={'Upload Accounts From File'} placement={'top'} arrow>
        <Button
          variant="contained"
          onClick={() => handleUpload()}
          disabled={!file || loading}
        >
          IMPORT
        </Button>
      </Tooltip>
  );

  return (
    <Template slot_1={info} slot_2={input} slot_3={type} slot_4={button}/>
  );
};

export default ImportProxies;
