// ----- Module ----- //
import React from "react";

// ----- MUI ----- //
import { Box } from "@mui/material";

// ----- Components ----- //
import Header from "../components/Header";
import Accounts from "./tabs/Accounts";
import Lists from "./tabs/Lists";
import Proxies from "./tabs/Proxies";
import Domains from "./tabs/Domains";

// ----- Utils ----- //
import { NavContext } from "../contexts/NavProvider";
import { Pages } from "../Utils/Types";

/**
 * Main Page component.
 */
const Main = (props: { page: number }) => {
  const {page} = props;
  const {tab, handleTabChange} = React.useContext(NavContext);

  React.useEffect(() => {
    handleTabChange(page);
  }, [page, handleTabChange]);

  return (
    <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <Box sx={{height: '7vh'}}>
        <Header/>
      </Box>

      <Box sx={{height: '93vh'}}>
        {tab === Pages.accounts && <Accounts/>}
        {tab === Pages.lists && <Lists/>}
        {tab === Pages.proxies && <Proxies/>}
        {tab === Pages.domains && <Domains/>}
      </Box>

    </Box>
  );
};

export default Main;
