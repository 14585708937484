// ----- Modules ----- //
import React, { useEffect } from "react";

// ----- MUI ----- //
import { Autocomplete, CircularProgress, debounce, TextField } from "@mui/material";

// ----- Utils ----- //
import { ListT } from "../../Utils/Types";
import { useConfiguredAxios } from "../../Utils/AxiosInstance";

const ProvidersSelect = (props: {
  onChange: any,
  disabled?: boolean,
}) => {
  const axiosInstance = useConfiguredAxios();

  const {onChange, disabled} = props;

  const [providersInput, setProvidersInput] = React.useState<string>('');
  const [providers, setProviders] = React.useState<ListT[]>([]);
  const [loadProviders, setLoadProviders] = React.useState(false);

  // ----- Functions ----- //
  const fetchGroups = async (input: string) => {
    if (!input) return setProviders([]);
    setLoadProviders(true);
    const result = await axiosInstance.get(`/api/proxies/providers?provider=${input}`);
    setProviders(result.data);
    setLoadProviders(false);
  };

  useEffect(() => {
    const debouncedFetch = debounce(fetchGroups, 250);
    debouncedFetch(providersInput);
    return () => debouncedFetch.clear();
  }, [providersInput]);

  return (
    <Autocomplete
      id="proxy-providers"
      size={'small'}

      disabled={disabled}
      options={providers || []}
      multiple
      autoHighlight
      fullWidth

      getOptionLabel={(option: any) => option.name}
      renderInput={(params) =>
        <TextField {...params} label="Providers" variant="outlined" size={'small'}
                   InputProps={{
                     ...params.InputProps,
                     endAdornment: (
                       <React.Fragment>
                         {loadProviders ? <CircularProgress color="inherit" size={20}/> : null}
                         {params.InputProps.endAdornment}
                       </React.Fragment>
                     ),
                   }}
        />}

      onChange={onChange}
      onInputChange={(e, value) => {
        setProvidersInput(value);
      }}

      noOptionsText={providers.length === 0 && providersInput ? 'No providers found' : 'Type to search'}
      filterOptions={(x) => x}
      loading={loadProviders}
    />
  );
};

export default ProvidersSelect;
