import ListsSelect from "../../../inputs/ListsSelect";
import { useState } from "react";
import { ListT, StatusT } from "../../../../Utils/Types";
import StatusSelect from "../../../inputs/StatusSelect";
import { Box, Button } from "@mui/material";
import { useConfiguredAxios } from "../../../../Utils/AxiosInstance";
import ListIcon from "@mui/icons-material/FormatListBulleted";
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { enqueueSnackbar } from "notistack";

const Lists = () => {
  const axiosInstance = useConfiguredAxios();

  const [lists, setLists] = useState<ListT[]>([]);
  const [status, setStatus] = useState<StatusT | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const HandleUpdate = async () => {
    setIsUpdating(true);
    await axiosInstance.post(`/api/lists/status`, {
      lists: lists.map(list => list.id),
      status: status?.id
    })
      .then((res) => {
        const accounts = res.data;
        if (accounts.length === 0)
          enqueueSnackbar('No accounts found', {variant: 'error'});
        else
          enqueueSnackbar(`Successfully updated ${accounts.length} accounts`, {variant: 'success'});
      })
      .catch(() => {
        enqueueSnackbar('Failed to update', {variant: 'error'});
      });

    setIsUpdating(false);
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, width: '100%'}}>

      <Box sx={{display: 'flex', gap: 1, alignItems: 'center', width: '100%'}}>
        <ListIcon sx={{fontSize: '35px'}}/>

        <ListsSelect
          default_lists={[]}
          onChange={(_: any, value: ListT[]) => {
            setLists(value);
          }}/>
      </Box>

      <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <SettingsIcon sx={{fontSize: '35px'}}/>

        <StatusSelect
          default_status={null}
          onChange={(_: any, value: StatusT) => {
            setStatus(value);
          }}
          system
        />

        <Button
          disabled={lists.length === 0 || !status || isUpdating}
          onClick={HandleUpdate}
          variant={'contained'}
          color={'primary'}
        >
          Update
        </Button>

      </Box>
    </Box>
  );
};

export default Lists;
