import axios from 'axios';
import { useSnackbar } from 'notistack';

export const useConfiguredAxios = () => {
  const {enqueueSnackbar} = useSnackbar();

  const axiosInstance = axios.create({
    withCredentials: true,
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 400: // Bad Request
            enqueueSnackbar(`${error.response.data} Hmmm... 🤔`, {variant: 'error'});
            throw error;
          case 401: // Unauthorized
            enqueueSnackbar('Unauthorized! Redirecting to login... 🏃‍♂️💨', {variant: 'error'});
            setTimeout(() => {
              window.location.href = '/login';
            }, 3000);
            break;
          case 403: // Forbidden
            enqueueSnackbar('Forbidden! This area is for VIPs only. 🕶️', {variant: 'error'});
            break;
          case 404: // Not Found
            enqueueSnackbar('Not found! Even Sherlock couldn’t find this one. 🕵️', {variant: 'error'});
            break;
          case 500: // Internal Server Error
            enqueueSnackbar('Internal server error! Please contact support. 🤖',
              {variant: 'error', autoHideDuration: 10000});
            break;
          default: // Other
            enqueueSnackbar('An error occurred! Gremlins must be at work. 🧟', {variant: 'error'});
            break;
        }
      }

      return error;
    }
  );

  return axiosInstance;
};
