// ----- Modules ----- //
import React, { ReactNode, useEffect, useState } from "react";
import { useConfiguredAxios } from "../Utils/AxiosInstance";

// ----- Utils ----- //
import { CcT, CreditCardT } from "../Utils/Types";
import WebSocketManager from "../services/WebSocketManager";

interface CreditCardsContext {
  creditCards: CreditCardT[];
  fetchCreditCardTypes: () => void;
  types: CcT[];
  handleEdit: (creditCard: CreditCardT) => void;
}

interface CreditsCardsProviderProps {children: ReactNode;}

const CreditCardsContext = React.createContext<CreditCardsContext>({
  creditCards: [],
  fetchCreditCardTypes: () => {},
  types: [],
  handleEdit: () => {},
});

/**
 * This component is a wrapper for the entire application.
 * It provides the context for the credit cards.
 * @param children - The children of the component.
 */
const CreditCardsProvider = ({children}: CreditsCardsProviderProps) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [creditCards, setCrediCards] = useState([] as CreditCardT[]);
  const [types, setTypes] = useState([]);


  const handleCCInfoChange = (method: string, data: CreditCardT) => {
    setCrediCards((currentCreditCards) => {
      console.log('method', method);
      console.log('data', data);
      switch (method) {
        case 'POST':
          return [data, ...currentCreditCards];
        case 'PUT':
          return currentCreditCards.map((a) => {
            if (a.id === data.id) return {...a, ...data};
            return a;
          });
        default:
          return currentCreditCards;
      }
    });
  };


  // ----- Effects ----- //
  useEffect(() => {
    const handleWebSocketMessage = (message: any) => {
      if (message.type === 'CREDIT_CARD')
        handleCCInfoChange(message.method, message.data.credit_card);
    };

    // Create a new instance of WebSocketManager
    const webSocketManager = new WebSocketManager(handleWebSocketMessage);

    fetchCreditCardTypes();

    // Cleanup function
    return () => {
      webSocketManager.disconnect(); // Disconnect WebSocket on unmount
    };
  }, []);

  const fetchCreditCardTypes = () => {
    axiosInstance.get('/api/creditcards/types')
      .then((response) => {
        setTypes(response.data);
      });
  };

  // ----- Functions ----- //
  const handleEdit = (creditCard: CreditCardT) => {

    if (!creditCards.some((c: CreditCardT) => c.id === creditCard.id)) {
      setCrediCards([...creditCards, creditCard]);
      return;
    }

    const newCreditCards = creditCards.map((c: CreditCardT) => {
      if (c.id === creditCard.id) return creditCard;
      return c;
    });
    setCrediCards(newCreditCards);
  };

  // ----- Render ----- //
  return (
    <CreditCardsContext.Provider value={{creditCards, fetchCreditCardTypes, types, handleEdit}}>
      {children}
    </CreditCardsContext.Provider>
  );
};

export { CreditCardsContext, CreditCardsProvider };
