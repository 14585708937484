// ----- Module ----- //
import React from "react";

// ----- MUI ----- //
import { IconButton, Tooltip } from "@mui/material";
// Icons
import DomainIcon from '@mui/icons-material/DnsOutlined';
import { getSelectedTheme } from "../../Utils/Colors";

// ----- Utils ----- //

/**
 * Modal for editing or creating a Domain
 */
const Domain = () => {
  // ----- Render ----- //
  return (
    <>
      <Tooltip title={'Not Implemented Yet'} placement={'top'}>
        <IconButton aria-label="Show Domain Info" component="span"
                    sx={{p: 0.25, position: 'relative', left: '-7px'}}>
          <DomainIcon sx={{color: getSelectedTheme().darkText, fontSize: '40px'}}/>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default Domain;
