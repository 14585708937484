import CheckIcon from "@mui/icons-material/Check";
import { getSelectedTheme } from "../../Utils/Colors";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";

const CellBoolean = (value: boolean) => (
  value ? <CheckIcon sx={{color: getSelectedTheme().success}}/> :
    <ClearIcon sx={{color: getSelectedTheme().error}}/>
);

export default CellBoolean;
