import { Box, Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Lists from "./Lists";
import Subnets from "./Subnets";
import React from "react";
import CategoryIcon from '@mui/icons-material/Category';
import Providers from "./Providers";
import Domains from "./Domains";
import CCTypes from "./CCTypes";

const Index = () => {
  const [category, setCategory] = React.useState(0);

  const Panel = (props: { children: React.ReactNode, value: number, index: number }) => (
    <Box hidden={props.value !== props.index} sx={{width: '100%'}}>
      {props.value === props.index && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          {props.children}
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, width: '100%'}}>

      <Box sx={{display: 'flex', gap: 1, alignItems: 'center', width: '100%'}}>
        <CategoryIcon sx={{fontSize: '35px'}}/>

        <FormControl fullWidth>
          <InputLabel id="cc-type-label" size={'small'}>Category</InputLabel>
          <Select
            id={'bulk-status-select'}
            value={category}
            label="Category"
            size={'small'}
            onChange={(e) => setCategory(parseInt(e.target.value + ''))}
          >
            <MenuItem key={0} value={0}>Lists</MenuItem>
            <MenuItem key={1} value={1}>Subnets</MenuItem>
            <MenuItem key={2} value={2}>Providers</MenuItem>
            <MenuItem key={3} value={3}>Domains</MenuItem>
            <MenuItem key={4} value={4}>CC Types</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Divider/>

      <Panel value={category} index={0}>
        <Lists/>
      </Panel>
      <Panel value={category} index={1}>
        <Subnets/>
      </Panel>
      <Panel value={category} index={2}>
        <Providers/>
      </Panel>
      <Panel value={category} index={3}>
        <Domains/>
      </Panel>
      <Panel value={category} index={4}>
        <CCTypes/>
      </Panel>
    </Box>
  );
};

export default Index;
