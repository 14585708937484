// ----- Modules ----- //
import React, { useCallback, useContext } from "react";
import { useSnackbar } from "notistack";

// ----- MUI ----- //
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography
} from "@mui/material";
// Icons
import TemplateIcon from '@mui/icons-material/DescriptionOutlined';
import UploadIcon from '@mui/icons-material/DriveFolderUpload';

// ----- Components ----- //
import Template from "../Template";

// ----- Utils ----- //
import { useConfiguredAxios } from "../../../../Utils/AxiosInstance";
import { CreditCardsContext } from "../../../../contexts/CreditCardsProvider";
import { AccountsContext } from "../../../../contexts/AccountsProvider";
import { getSelectedTheme } from "../../../../Utils/Colors";
import { FONTS } from "../../../../index";

enum COLUMNS {
  'LISTS',
  'IDX',
  'EMAIL',
  'PASSWORD',
  'NAME',
  'PHONE',
  'ADDRESS',
  'CITY',
  'ZIP_CODE',
  'STATE',
  'COUNTRY',
  'SEPARATOR',
  'TYPE',
  'LAST_4',
  'CVV',
  'EXP',
  'CURRENCY',
  'SAFEKEY',
}

const ImportAccounts = () => {
  const axiosInstance = useConfiguredAxios();

  // ----- Context ----- //
  const {enqueueSnackbar} = useSnackbar();
  const {types} = useContext(CreditCardsContext);
  const {fetchData} = useContext(AccountsContext);

  const [file, setFile] = React.useState<File | null>(null);
  const [sync1Ticket, setSync1Ticket] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);

  const [failed, setFailed] = React.useState<{ toCreate: string[], toSync: string[] }>({toCreate: [], toSync: []});

  const [total, setTotal] = React.useState<number>(0);
  const [uploaded, setUploaded] = React.useState<number>(0);


  const handleUpload = useCallback(() => {
    if (!file) {
      enqueueSnackbar('No file selected', {variant: 'error'});
      return;
    }
    setLoading(true);
    formatFile(file);
  }, [file, sync1Ticket]);

  const formatFile = (file: File) => {
    const reader = new FileReader();

    reader.readAsText(file);

    reader.onload = async function () {
      const lines = reader.result?.toString().split('\n');

      if (!lines) {
        enqueueSnackbar('Invalid file', {variant: 'error'});
        return;
      }

      lines?.shift();

      const listsArr = [] as string[];
      for (const line of lines) {
        const account = line.split(',') as string[];
        const lists = account[COLUMNS.LISTS].split('-');
        for (const list of lists) {
          if (!listsArr.includes(list)) listsArr.push(list);
        }
      }

      let listNames = await axiosInstance.get('/api/lists/names') as string[];
      const listsToCreate = listsArr.filter((list: string) => !listNames.find((n) => n.toLowerCase() === list.toLowerCase()));

      if (listsToCreate.length > 0) {
        for (const list of listsToCreate) {
          console.log(list);
          await axiosInstance.post('/api/lists', {name: list, sync: true})
            .then(async () => {
              enqueueSnackbar(`List ${list} created successfully!`, {variant: 'success'});
              listNames.push(list);
            }).catch(() => {
              enqueueSnackbar(`Failed to create list ${list}.`, {variant: 'error'});
            });
        }
      }

      const accounts = [] as any[];

      setTotal(lines.length);

      window.onbeforeunload = () => true;

      for (const line of lines) {

        if (!line.includes('@')) continue;

        const account = line.split(',') as string[];
        console.log(account.length);
        if (account.length !== 16) {
          enqueueSnackbar('Invalid file', {variant: 'error'});
          return;
        }

        accounts.push({
          info: {
            lists: account[COLUMNS.LISTS].split('-').map((list: string) => listNames.find((n) => n.toLowerCase() === list.toLowerCase())),
            email: account[COLUMNS.EMAIL],
            password: account[COLUMNS.PASSWORD],
            name: account[COLUMNS.NAME],
            phone: account[COLUMNS.PHONE],
            address: account[COLUMNS.ADDRESS],
            city: account[COLUMNS.CITY],
            zip: account[COLUMNS.ZIP_CODE],
            state: account[COLUMNS.STATE],
            country: account[COLUMNS.COUNTRY]
          },
          cc: {
            type_id: types.find((t) => t.name.toLowerCase() === account[COLUMNS.TYPE].toLowerCase())?.id,
            last_4: account[COLUMNS.LAST_4],
            cvv: account[COLUMNS.CVV],
            exp: account[COLUMNS.EXP],
            currency: account[COLUMNS.CURRENCY],
            safekey: account[COLUMNS.SAFEKEY],
          },
          state: {
            messi: {
              loading: true,
              error: false,
            },
            oneTicket: {
              loading: true,
              error: false,
            }
          }
        });
      }

      enqueueSnackbar(`Uploading ${accounts.length} accounts...`, {variant: 'info'});

      for (let i = 0; i < accounts.length; i++) {
        await uploadAccount(accounts[i]);
        setUploaded(i + 1);
      }

      if (failed.toCreate.length > 0)
        enqueueSnackbar(`Failed to create ${failed.toCreate.length} accounts: ${failed.toCreate.join(', ')}`, {variant: 'error'});

      if (failed.toSync.length > 0)
        enqueueSnackbar(`Failed to sync ${failed.toSync.length} accounts: ${failed.toSync.join(', ')}`, {variant: 'error'});

      enqueueSnackbar(`Successfully uploaded ${accounts.length - failed.toCreate.length} accounts!`, {variant: 'success'});

      window.onbeforeunload = null;

      setFailed({toCreate: [], toSync: []});
      setLoading(false);
      setFile(null);
      fetchData();
    };
  };

  const uploadAccount = useCallback(async (account: any) => {
    try {
      await axiosInstance.post('/api/accounts/upload', account);
      console.log('account uploaded to messi');

      if (sync1Ticket) {
        console.log('syncing to 1ticket');
        try {
          await axiosInstance.post('/api/account-manager/email', {
            email: account.info.email,
            password: account.info.password,
          });
          console.log('account synced to 1ticket');
        } catch (error) {
          setFailed(prev => ({...prev, toSync: [...prev.toSync, account.info.email]}));
          console.log(error);
        }
      }
    } catch (error) {
      setFailed(prev => ({...prev, toCreate: [...prev.toCreate, account.info.email]}));
      console.log(error);
    }
  }, [sync1Ticket]);

  const input = (
    <Box sx={{position: 'relative'}}>
      <Typography
        sx={{position: 'absolute', color: getSelectedTheme().lightText, bottom: -20, whiteSpace: 'nowrap'}}
        fontFamily={FONTS} fontSize={14}>
        {file ? file.name : 'No file selected'}
      </Typography>

      <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{width: '100%'}}>
        <Tooltip title={'Open Template'} placement={'top'} arrow>
          <Button href={'https://docs.google.com/spreadsheets/d/1zry_S2Xlp-uCCJH3E1PcbjeZwoM9w7QJBLRdyI-0Aq8/edit'}
                  target={'_blank'} sx={{borderTopRightRadius: 0, borderBottomRightRadius: 0, width: 20}}
                  fullWidth>
            <TemplateIcon/>
          </Button>
        </Tooltip>
        <Tooltip title={'.CSV Only'} placement={'top'} arrow>
          <Button component="label" sx={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} endIcon={<UploadIcon/>}
                  fullWidth>
            <Typography sx={{pt: 0.5}} fontFamily={FONTS} fontSize={20}>
              Upload
            </Typography>
            <input type="file" accept=".csv" hidden onChange={(e) => setFile(e.target.files?.[0] ?? null)}/>
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );

  const info = (
    loading && total > 0 &&
    <Typography sx={{mr: 1, textAlign: 'center'}} fontFamily={FONTS}
                fontSize={20}>
      {uploaded}/{total}
    </Typography>
  );

  const type = (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Tooltip title={'Sync to 1Ticket'} placement={'top'} arrow>
        <FormControlLabel
          control={
            <Switch
              size={'small'}
              checked={sync1Ticket}
              onChange={() => setSync1Ticket(!sync1Ticket)}
              inputProps={{'aria-label': 'controlled'}}
            />
          }
          label="Sync?"
        />
      </Tooltip>
    </Box>
  );

  const button = (
    loading ? <Box sx={{px: 0.97}}><CircularProgress size={25}/></Box> :
      <Tooltip title={'Upload Accounts From File'} placement={'top'} arrow>
        <Button
          variant="contained"
          onClick={() => handleUpload()}
          disabled={!file || loading}
        >
          IMPORT
        </Button>
      </Tooltip>
  );

  return (
    <Template slot_1={info} slot_2={input} slot_3={type} slot_4={button}/>
  );
};

export default ImportAccounts;
