// ----- Modules ----- //
import React from "react";

// ----- MUI ----- //
import { Box, Divider } from "@mui/material";
import UserExportIcon from "@mui/icons-material/ContactPageOutlined";
import UserImportIcon from "@mui/icons-material/GroupAddOutlined";
import CardImportIcon from "@mui/icons-material/AddCard";
import ProxiesImportIcon from "@mui/icons-material/Storage";

// ----- Components ----- //
import ExportAccounts from "./exportImport/ExportAccounts";
import ImportAccounts from "./exportImport/ImportAccounts";
import ImportCreditCards from "./exportImport/ImportCreditCards";
import ImportProxies from "./exportImport/ImportProxies";
import { ToolTitle } from "./Index";

const ExportImport = () => {

  return (
    <>
      <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
        {ToolTitle({title: 'Export Accounts', icon: <UserExportIcon sx={{fontSize: '35px'}}/>})}
        <ExportAccounts/>
      </Box>

      <Divider sx={{width: '90%'}}/>

      <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
        {ToolTitle({
          title: 'Import Accounts',
          icon: <UserImportIcon
            sx={{fontSize: '35px', position: 'relative', left: -3, transform: 'scaleX(-1)'}}/>
        })}
        <ImportAccounts/>
      </Box>

      <Divider sx={{width: '90%'}}/>

      <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
        {ToolTitle({
          title: 'Import Credit Cards',
          icon: <CardImportIcon
            sx={{fontSize: '35px', position: 'relative', left: -3, transform: 'scaleX(-1)'}}/>
        })}
        <ImportCreditCards/>
      </Box>

      <Divider sx={{width: '90%'}}/>

      <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
        {ToolTitle({
          title: 'Import Proxies',
          icon: <ProxiesImportIcon sx={{fontSize: '35px'}}/>
        })}
        <ImportProxies/>
      </Box>
    </>
  );
};

export default ExportImport;
