// ----- Modules ----- //
import React from "react";
import { useSnackbar } from "notistack";
import moment from "moment";

// ----- MUI ----- //
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  LinearProgress,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import styled from "@emotion/styled";
import ErrorIcon from '@mui/icons-material/ErrorOutline';

// ----- Utils ----- //
import { useConfiguredAxios } from "../../Utils/AxiosInstance";
import { AccountsRequestsT } from "../../Utils/Types";
import { getSelectedTheme } from "../../Utils/Colors";
import { FONTS } from "../..";

const RequestsStatus = () => {
  const axiosInstance = useConfiguredAxios();

  const [requests, setRequests] = React.useState<AccountsRequestsT[]>([] as AccountsRequestsT[]);

  const fetchRequests = () => {
    axiosInstance.get('/api/requests/user')
      .then((response) => {
          setRequests(response.data.requests);
        }
      );
  };

  React.useEffect(() => {
    fetchRequests();
    const interval = setInterval(() => {
      fetchRequests();
    }, 600000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{
      position: 'absolute',
      bottom: '0',
      left: '50%',
      transform: 'translate(-50%)',
    }}>
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '25px'}}>
        {requests.length > 0 ? requests.map((request) => <Request request={request}/>) : null}
      </Box>
    </Box>
  );
};


const Request = (props: { request: AccountsRequestsT }) => {
  const axiosInstance = useConfiguredAxios();

  const {request} = props;

  const {enqueueSnackbar} = useSnackbar();

  const [show, setShow] = React.useState(true);
  const [count, setCount] = React.useState<number | undefined>(undefined);
  const [done, setDone] = React.useState(false);

  const handleDismiss = () => {
    // call api to delete the request from the database
    axiosInstance.delete(`/api/requests/${request.id}`)
      .then(() => {
        setShow(false);
      });
  };

  const fetchCount = () => {
    axiosInstance.get(`/api/requests/count/${request.id}`)
      .then((response) => {
          setCount(response.data?.count);
        }
      );
  };

// useEffect for fetching count and setting interval
  React.useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null; // Declare the intervalId variable

    const startFetchingCount = () => {
      fetchCount(); // Call immediately once request.id is available

      // Set interval for further calls
      intervalId = setInterval(() => {
        fetchCount();
      }, 10000); // Fetch every 10 seconds
    };

    // Start fetching if request.id is available and count is not done
    if (request.id && (count === undefined || count < request.qty)) {
      startFetchingCount();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clear the interval when the component unmounts or the condition is met
      }
    };
  }, [request, count]);

// useEffect for checking the condition
  React.useEffect(() => {
    if (count !== undefined && count >= request.qty) {
      enqueueSnackbar('The accounts that you requested have been created! 🎉', {variant: 'success'});
      setDone(true);
    }
  }, [count, request.qty]);

  return (
    <>
      <Collapse in={show} sx={{px: 0.1}}>

        {InfoTooltip(request, (
          <Box
            onClick={() => {if (done) handleDismiss();}}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              px: 2,
              height: '50px',
              bgcolor: getSelectedTheme().dark,
              borderTopLeftRadius: '7px',
              borderTopRightRadius: '7px',
              cursor: done ? 'pointer' : 'default',
              width: '300px',
            }}
          >
            {done ? <CheckIcon sx={{color: getSelectedTheme().success, fontSize: '25px'}}/> :
              !moment(request.created_at).isBefore(moment().subtract(1, 'days')) ?
                <CircularProgress sx={{color: getSelectedTheme().accent}} size={20}/> :
                <Tooltip title={'Request created over 1 day ago. It may have failed. Click to cancel request.'}
                         placement={'top'} arrow>
                  <IconButton aria-label="Dismiss request" component="span" onClick={() => handleDismiss()} sx={{p: 0}}>
                    <ErrorIcon sx={{color: getSelectedTheme().error, fontSize: '25px'}}/>
                  </IconButton>
                </Tooltip>}

            <Typography
              sx={{
                fontFamily: FONTS,
                fontSize: 20,
                color: getSelectedTheme().lightText,
                mt: 0.75
              }}>
              {count !== undefined ? count.toString().padStart(request.qty.toString().length, '0') :
                '-'.repeat(request.qty.toString().length)}/{request.qty}
              {' '}Accounts Requested At {moment(request.created_at).format('h:mm a')}
            </Typography>
          </Box>
        ))}

        <LinearProgress
          sx={{
            width: '100%', height: '3px', bgcolor: getSelectedTheme().darkLine,
            '& .MuiLinearProgress-bar': {
              bgcolor: done ? getSelectedTheme().success :
                moment(request.created_at).isBefore(moment().subtract(1, 'days')) ? getSelectedTheme().error : getSelectedTheme().accent
            }
          }}
          variant="determinate" value={count !== undefined ? (count / request.qty) * 100 : 0}/>
      </Collapse>
    </>
  );
};

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} children={props.children}/>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: getSelectedTheme().darkLine,
    fontFamily: FONTS,
    fontSize: 16,
    borderRadius: '7px',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.25)',
  },
}));

const InfoTooltip = (request: AccountsRequestsT, children: any) => {
  return (
    <HtmlTooltip title={
      <React.Fragment>
        <Box sx={{pt: 0.5}}/>
        {request.lists?.length > 0 && (
          <Typography sx={{fontFamily: FONTS}}>
            Lists: {request.lists.map((list) => list.name).join(', ')}
          </Typography>
        )}
        {request.city && (
          <Typography sx={{fontFamily: FONTS}}>
            City: {request.city ? request.city : '---'}
          </Typography>
        )}
        {request.state && (
          <Typography sx={{fontFamily: FONTS}}>
            State: {request.state ? request.state : '---'}
          </Typography>
        )}
        {request.cc_type && (
          <Typography sx={{fontFamily: FONTS}}>
            Card: {request.cc_type ? request.cc_type.name : '---'}
          </Typography>
        )}
        {request.cc_currency && (
          <Typography sx={{fontFamily: FONTS}}>
            Currency: {request.cc_currency ? request.cc_currency : '---'}
          </Typography>
        )}
        <Typography sx={{fontFamily: FONTS}}>
          Sync: {request.manager_sync ? 'Yes' : 'No'}
        </Typography>
      </React.Fragment>
    } placement={'top'}>
      {children}
    </HtmlTooltip>
  );
};

export default RequestsStatus;
