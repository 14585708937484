// ----- Modules ----- //
import React from "react";

// ----- MUI ----- //
import { Box, Typography } from "@mui/material";

// ----- Utils ----- //
import { NavContext } from "../contexts/NavProvider";
import { getSelectedTheme } from "../Utils/Colors";
import { FONTS } from "../index";

/**
 * Navigation component for the app. Allows the user to switch between accounts and proxies.
 */
const Navigation = () => {
  const {tab, handleTabChange} = React.useContext(NavContext);

  return (
    <Box
      id={'navigation'}
      sx={{
        px: 2,
        position: 'absolute',
        top: 10,
        left: '50%',
        transform: 'translate(-50%, 0)',
        height: '60px',
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: getSelectedTheme().dark,
        color: getSelectedTheme().lightText,
      }}>
      <Typography fontFamily={FONTS} fontSize={24}
                  sx={{cursor: 'pointer'}}
                  onClick={() => handleTabChange(0)}
                  color={tab === 0 ? getSelectedTheme().lightText : getSelectedTheme().darkText}>
        ACCOUNTS
      </Typography>
      <Typography fontFamily={FONTS} fontSize={24} sx={{cursor: 'pointer'}}
                  onClick={() => handleTabChange(1)}
                  color={tab === 1 ? getSelectedTheme().lightText : getSelectedTheme().darkText}>
        LISTS
      </Typography>
      <Typography fontFamily={FONTS} fontSize={24} sx={{cursor: 'pointer'}}
                  onClick={() => handleTabChange(2)}
                  color={tab === 2 ? getSelectedTheme().lightText : getSelectedTheme().darkText}>
        PROXIES
      </Typography>
      <Typography fontFamily={FONTS} fontSize={24} sx={{cursor: 'pointer'}}
                  onClick={() => handleTabChange(3)}
                  color={tab === 3 ? getSelectedTheme().lightText : getSelectedTheme().darkText}>
        DOMAINS
      </Typography>
    </Box>
  );
};

export default Navigation;
