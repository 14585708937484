import WebSocketService, { DispatchFunction } from './WebSocketService';

class WebSocketManager {
  constructor(private handleMessage: DispatchFunction) {
    WebSocketService.registerHandler(this.handleMessage);
  }

  // If you need to disconnect, you can expose this functionality as well
  disconnect = (): void => {
    WebSocketService.unregisterHandler(this.handleMessage);
    WebSocketService.disconnect();
  };
}

export default WebSocketManager;
